import React from 'react';
import Header from "../../components/layout/Header";
import CoverHeader from "../../components/layout/CoverHeader";
import Footer from "../../components/layout/Footer";

const EconomicImpact = () => {
    return (
        <>
            <Header/>
            <CoverHeader
                title="Economic Impact"
                description="At Ebusaka, we are not only committed to driving positive social and environmental change but also to creating a significant economic impact. Our initiatives align with several United Nations Sustainable Development Goals (SDGs) that promote economic growth, sustainable industries, and employment opportunities."
                imguri="../../assets/resources/greensprout.jpg"
            />
            <section id="sdg">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6" style={{marginBottom: 60}}>
                            <div className="block d-flex align-items-center" style={{background: "#A31C44"}}>
                                <img src="/assets/resources/sdg-8.png" className="img-fluid" alt="sdg - 11" />
                            </div>
                            <h2 style={{color: "#A31C44"}}>SDG 8 - Decent Work and Economic Growth</h2>
                            <p>Ebusaka plays a vital role in promoting employment opportunities for waste collectors, recyclers, and stakeholders in the circular economy. By providing a platform that connects these key players with residents and businesses, we contribute to the growth of the local economy and ensure decent work for individuals in the waste management sector.</p>
                        </div>

                        <div className="col-12 col-md-6">
                            <div className="block d-flex align-items-center" style={{background: "#F2692B"}}>
                                <img src="/assets/resources/sdg-9.png" className="img-fluid" alt="sdg - 12" />
                            </div>
                            <h2 style={{color: "#F2692B"}}>SDG 9 - Industry, Innovation, and Infrastructure</h2>
                            <p>As we connect recyclers with a reliable source of raw materials through our platform, we actively encourage the development of a local recycling industry. This fosters innovation and creates new economic opportunities in the recycling sector.</p>
                        </div>

                        <div className="col-12 col-md-6">
                            <div className="block d-flex align-items-center" style={{background: "#BF8D2C"}}>
                                <img src="/assets/resources/sdg-12.png" className="img-fluid" alt="sdg - 12" />
                            </div>
                            <h2 style={{color: "#BF8D2C"}}>SDG 12 - Responsible Consumption and Production</h2>
                            <p>Ebusaka's focus on reducing waste management and landfill costs has a significant impact on local councils and businesses. By promoting circular economy principles and encouraging responsible waste management practices, we help organizations save costs and allocate resources more efficiently. Additionally, the sale of recycled materials generates revenue and fosters economic growth.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="impact">
                <div className="container">
                    <div>
                        <h1>Driving Local Employment</h1>
                        <p>Through our initiatives, Ebusaka empowers waste collectors and recyclers with sustainable employment opportunities. By providing a steady stream of waste materials and improving waste collection efficiency, we support the livelihoods of these essential contributors to the circular economy.</p>
                    </div>

                    <div style={{marginTop: 66}}>
                        <h1>Supporting Local Businesses</h1>
                        <p>Ebusaka's services extend to local businesses, offering waste collection solutions that are both cost-effective and environmentally responsible. By streamlining waste management processes, we enable businesses to focus on their core operations and contribute to the overall economic development of their communities.</p>
                    </div>

                    <div style={{marginTop: 66}}>
                        <h1>Creating a Green Economy</h1>
                        <p>Our emphasis on recycling and waste-to-energy solutions contributes to the development of a green economy. By harnessing the potential of waste as a valuable resource, we promote sustainable economic growth and environmental stewardship.</p>
                    </div>

                    <div style={{marginTop: 66}}>
                        <h1>Attracting Sustainable Investments</h1>
                        <p>Ebusaka's innovative approach to waste management and its commitment to the circular economy have caught the attention of investors and sustainability-focused institutions. As we grow our operations and expand across Africa, we attract investments that support our mission of creating lasting economic and environmental impact.</p>
                    </div>
                </div>
                <img src="/assets/resources/watermark.svg" alt="watermark"/>
            </section>

            <section className="impact" style={{background: "white"}}>
                <div className="container">
                    <div>
                        <h1>Promoting Circular Business Models</h1>
                        <p>Our platform encourages businesses to embrace circular business models that prioritize sustainability and resource efficiency. By diverting waste from landfills and promoting a closed-loop system, we drive cost savings and foster economic resilience.</p>
                    </div>

                    <div style={{marginTop: 45}}>
                        <h1>Building Resilient Communities</h1>
                        <p>Economic empowerment through sustainable waste management fosters resilient communities. By creating opportunities for waste collectors and recyclers, we contribute to economic stability and poverty reduction.</p>
                    </div>

                    <div style={{marginTop: 45}}>
                        <p>At Ebusaka, we understand that driving economic impact is as essential as achieving social and environmental goals. Through our integrated waste management solutions and commitment to the circular economy, we continue to make strides in building stronger, greener, and more prosperous communities. Join us in creating a sustainable future where economic growth goes hand in hand with environmental stewardship. Together, we can build a world where waste becomes a valuable resource and every action contributes to a thriving economy and a healthier planet.</p>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    );
};

export default EconomicImpact;
