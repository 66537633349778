import React from 'react';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

const Assistance = (props) => {

    const {title, description, tag, route} = props;

    return (
        <section id="assistance" className="text-center">
            <h1>{title}</h1>
            <p>{description}</p>
            <Link to={route} className="btn btn-primary">{tag}</Link>
        </section>
    );
};

Assistance.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired
};

export default Assistance;
