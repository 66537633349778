import React from 'react';
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import SEO from "../components/layout/Seo";

const Privacy = () => {
    return (
        <>
            <SEO
                title="Privacy Policy"
                description="Ebusaka has created this privacy policy in order to demonstrate our firm commitment to your privacy. The following discloses our information gathering and dissemination practices relating to the Ebusaka Web Application (“the App”), the delivery of our services and other interactions with you. Please note that when you access an4y external links from the App, such external websites may have different privacy policies from the sites and Ebusaka is not responsible for the privacy practices of such external links."
            />
            <Header/>
            <section id="privacy">
                <div className="container">
                    <h1>Privacy Policy</h1>
                    <h2>Last Updated Aug 1, 2023</h2>

                    <p>Ebusaka has created this privacy policy in order to demonstrate our firm commitment to your privacy. The following discloses our information gathering and dissemination practices relating to the Ebusaka Web Application (“the App”), the delivery of our services and other interactions with you. Please note that when you access an4y external links from the App, such external websites may have different privacy policies from the sites and Ebusaka is not responsible for the privacy practices of such external links.</p>

                    <div className="mt-4">
                        <span>1. Interpretation</span>
                        <p><strong>"Biometric Data"</strong> means personal data resulting from specific technical processing relating to the physical, physiological or behavioural characteristics of a natural person, which allow or confirm the unique identification of that natural person, such as facial images or dactyloscopic (finger print) data.</p>
                        <p><strong>"Consent"</strong> of the data subject means any freely given, specific, informed and unambiguous indication of the data subject’s wishes by which he or she, by a statement or by a clear affirmative action, signifies agreement to the processing of personal data relating to him or her.</p>
                        <p><strong>"Data"</strong> means individual facts, statistics, or items of information regarding an individual. Data can refer to automated data and manual data.</p>
                        <p><strong>"Data Subject"</strong> means an identified or identifiable natural person (see Personal Data).</p>
                        <p><strong>"Data Protection Officer (DPO)"</strong> the person required to be appointed in specific circumstances under the regulations. The DPO oversees how we collect, use, share and protect information.</p>
                        <p><strong>"Explicit Consent"</strong> means consent which requires a very clear and specific statement on the part of the Data Subject.</p>
                        <p><strong>"Personal Data"</strong> means any information relating to an identified or identifiable natural person (‘data subject’); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.</p>
                        <p><strong>"Processing or Process"</strong> means any operation or set of operations which is performed on personal data or on sets of personal data, whether or not by automated means, such as collection, recording, organisation, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.</p>
                        <p><strong>"Records"</strong> documents in every format created and received by individuals or organisations in the course of conduct of affairs and accumulated as evidence of these activities.</p>
                        <p><strong>"Supervisory Authority"</strong> means an independent public authority which is established under the Data Protection Act, that is, the Zambia Data Protection Authority</p>
                        <p><strong>"Ebusaka"</strong> means Ebusaka Waste Management Limited, a company duly incorporated under the laws of Zambia, whose registered address is at Plot 92, Sub 10 off 916, Lusaka South, Zambia</p>
                        <p><strong>"Ebusaka Web App or Our App or the App"</strong> means the Ebusaka Web Application</p>
                    </div>

                    <div className="mt-4">
                        <span>2. Data Collection</span>
                        <p>Ebusaka may collect Personal Information which information alone or in combination with other information may be used to identify you. This could be your name, your address, your mobile number, email address, home or business address or any other location from which you use our services. .</p>
                    </div>

                    <div className="mt-4">
                        <span>3. Device Information</span>
                        <p>When you are connected to the App, we may collect device-specific information such as your device model, operating system, unique device identifiers, and mobile network information.</p>
                        <p>Server logs that are maintained by us or by our third-party service providers may contain information about the services that you have used, and your device-specific information. We may use your device’s IP address or MAC address to track device-event information such as crashes, system activity, hardware settings, browser type, browser language, the date and time of your request and referral URL.</p>
                        <p>When you visit, use and interact with the Application, we may receive certain information about your visit, use or interactions. For example, we may monitor the number of people that have visited the Application.</p>
                        <p>In particular, the following information is created and automatically logged in our systems.</p>
                        <ul>
                            <li><p><strong>Log Data:</strong> Information that your browser automatically sends whenever you visit the App. This includes your Internet Protocols address, browser type and settings, the date and time of your request and how you interacted with the Application.</p></li>
                            <li><p><strong>Usage Information:</strong> We collect information about how you use our App, such as the actions you take, and the time, frequency and duration of your activities.</p></li>
                        </ul>
                    </div>

                    {/*<div className="mt-5">*/}
                    {/*    <span>4. How the Data is collected?</span>*/}
                    {/*    <p>We collect your Personal Information in connection with the services provided to you. We also may use any number of tools to collect information about you, your computer access points, and mobile devices that you use to connect to the App.</p>*/}
                    {/*    <p>Ebusaka may collect additional information from the App each time you visit the App.</p>*/}
                    {/*    <p>Use of your Personal Information</p>*/}
                    {/*    <ul>*/}
                    {/*        <li><p>Ebusaka may use your Personal Information to contact you via email, telephone or mobile device in order to give you updates about Ebusaka’s special events, new services, payment confirmations or other promotions that may be of interest to you.</p></li>*/}
                    {/*        <li><p>We also use return e-mail addresses to answer the e-mail we receive from you. We may also use your IP address, or unique devices identification numbers to help protect Ebusaka and our internet service providers from fraud.</p></li>*/}
                    {/*        <li><p>Additional uses of your non-personal and Personal Information will allow us to tailor products and services specific to your needs, to help organise and manage our relationship with you to enforce the App’s Terms of Use.</p></li>*/}
                    {/*        <li><p>We may also use non-personal aggregate information to improve our products and services offerings. Such information may also be used to analyse the effectiveness of our services.</p></li>*/}
                    {/*    </ul>*/}
                    {/*</div>*/}

                    <div className="mt-4">
                        <span>4. Consent</span>
                        <p>If we use your sensitive Personal Information, such as biometric data, we will ask for your explicit consent.</p>
                        <p>We will ensure that you are informed when making your decision and that you are aware that you can remove your consent at any time by contacting us.</p>
                        <p>3.	We ensure your consent is obtained under the following principles: Positive Action (Clear affirmative action by you is required), free will, specific, recorded and can be withdrawn at any time.</p>
                    </div>

                    <div className="mt-4">
                        <span>5. Data Protection Officer</span>
                        <p>To ensure that your rights are protected, our Data Protection Officer oversees the collection, use, sharing and protection of your information. The Data Protection Officer may be contacted by email at <strong style={{color: "#024731"}}>info@ebusaka.com</strong> by telephone on <strong style={{color: "#024731"}}>+260 975 200 030</strong> or by writing to The Data Protection Officer, at Plot 92, Sub 10 off 916, Lusaka South, Zambia</p>
                    </div>

                    <div className="mt-4">
                        <span>6. Keeping your information safe and secure</span>
                        <p>We protect your information with security measures under the laws that apply.  The collection, use, sharing, protection and deletion of your information is overseen by our Data Protection Officer.</p>
                        <p>When you contact us to ask about your information, we may ask you to identify yourself. This is to help us protect your information.</p>
                        <p>To meet our legal and regulatory obligations, we hold your information while you hold an account with us and for a period of time after that subject to legal, regulatory and business requirements, which may require us to hold the information for a longer period. </p>
                        <p>We continuously assess and delete data to ensure it is not held for longer than necessary.</p>
                    </div>

                    <div className="mt-4">
                        <span>7. Sharing your information with third parties</span>
                        <p>We may share your information with third parties sometimes, in order to: provide services, analyse information, research your experiences dealing with us and to protect both our interests.</p>
                        <p>The information is only shared upon assurance that the third party has a functional data protection policy in place and where the same is required by law.</p>
                    </div>

                    <div className="mt-4">
                        <span>8. Your rights regarding your Personal Information</span>
                        <p>If you wish to exercise your Personal Information rights, please contact the Data Protection Officer by email at <strong style={{color: "#024731"}}>info@ebusaka.com</strong>, by telephone on <strong style={{color: "#024731"}}>+260 975 200 030</strong> or by writing to The Data Protection Officer, Plot 92, Sub 10 off 916, Lusaka South, Zambia</p>
                        <p>When you contact us to ask about your information, we may ask you to identify yourself, this is to help us protect your information.</p>
                        <p>You have the right to obtain information, however this right cannot affect the rights and freedoms of others. We cannot therefore provide information on or about other people without their consent.</p>
                        <p>We will provide your information without charge. As permitted under the law, where information requests are manifestly unfounded or excessive, we may either charge a reasonable fee or refuse to act on the request.</p>
                    </div>

                    <div className="mt-4">
                        <span>9. Access to your Personal Information</span>
                        <p>You can request for the following information:</p>
                        <ul>
                            <li><p>the information we hold on you;</p></li>
                            <li><p>the purposes of the processing;</p></li>
                            <li><p>the categories of personal data concerned;</p></li>
                            <li><p>the recipients or categories of recipient to whom the personal data have been or will be disclosed;</p></li>
                            <li><p>where the personal data are not collected from you, any available information as to their source;</p></li>
                            <li><p>the significance and the envisaged consequences of such processing for you.</p></li>
                        </ul>
                    </div>

                    <div className="mt-4">
                        <span>10. Updating your Personal Information</span>
                        <p>You may update or correct any of your personal details. Please contact us at <strong style={{color: "#024731"}}>+260 975 200 030</strong> or call to any of our offices.</p>
                    </div>

                    <div className="mt-4">
                        <span>11. Withdrawing your consent</span>
                        <p>If you have given us consent in relation to the use of your Personal Information, you can change your mind and withdraw your consent. Please contact us at +260 975 200 030or call to any of our offices.</p>
                    </div>

                    <div className="mt-4">
                        <span>12. Restricting and objecting to processing your Personal Information</span>
                        <p>You have the right to restrict or object to us processing your Personal Information. We will require your consent to further process this information once restricted.</p>
                    </div>

                    <div className="mt-4">
                        <span>13. Deleting your Personal Information (your right to be forgotten)</span>
                        <p>You may ask us to delete your Personal Information or we may delete your Personal Information if:</p>
                        <ul>
                            <li><p>the personal data are no longer necessary in relation to the purposes for which they were collected or processed;</p></li>
                            <li><p>you withdraw your consent for whichever reasons;</p></li>
                            <li><p>the personal data have been unlawfully processed;</p></li>
                            <li><p>the personal data have to be erased for compliance with a legal obligation.</p></li>
                        </ul>
                    </div>

                    <div className="mt-4">
                        <span>14. Moving your Personal Information (your right to data portability)</span>
                        <p>If you request and where possible we can share a digital copy of your information directly with you or another organisation.</p>
                        <p>We do not share information processed under legal obligation or our legitimate interest for portability, this is in line with the laws of Data Protection.</p>
                    </div>

                    <div className="mt-4">
                        <span>15. Right to lodge a complaint with a ‘Supervisory Authority’</span>
                        <p>If you have a complaint about your Personal Information, please contact us on <strong style={{color: "#024731"}}>+260 975 200030</strong> or contact a member of staff in any of our offices.</p>
                        <p>You may also make a complaint to the Data Protection Officer, by email at <strong style={{color: "#024731"}}>info@ebusaka.com</strong>, by telephone on <strong style={{color: "#024731"}}>+260 975 200 030</strong> or by writing to The Data Protection Officer, Plot 92, Sub 10 off 916, Lusaka South, Zambia</p>
                        <p>Any complaint you make to us will be investigated as fully as possible. Please provide as much information as possible to help us quickly resolve your complaint.</p>
                    </div>

                    <div className="mt-4">
                        <span>16. Updates to this Privacy Policy</span>
                        <p>From time to time we will update this policy if we change how we use your information, change our technology or change our products. The most up to date notice will always be on our web-site privacy policy.</p>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    );
};

export default Privacy;
