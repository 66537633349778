import React, {useState} from 'react';
import PropTypes from "prop-types";
import Assistance from "./Assistance";

const Accordion = (props) => {

    let {data, title} = props;
    const [activeIndex, setActiveIndex] = useState(null);

    const handleAccordionToggle = (index) => {
        if(activeIndex === index) return null;
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };


    return (
        <section id="accordion">
            <div className="container">
                <h1>{title}</h1>
                <p>Explore our comprehensive list of frequently asked questions to find answers to common queries. We've compiled these FAQs to address some of the most common concerns our users have. You might find the solution you need right here!</p>
                <div className="accordion" id="accordionFaq">
                    {data.map((item, index) => {
                        const isExpanded = index === activeIndex;
                        return(
                            <div className="card" key={index}>
                                <div className="card-header">
                                    <h2 className="mb-0 d-flex align-items-center">
                                        <button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target={`#collapse${index}`} aria-expanded="true" aria-controls="collapseOne" style={isExpanded ? {fontFamily: "Maax-Bold", textDecoration: "none"} : null} onClick={() => handleAccordionToggle(index)}>
                                            {item.title}
                                        </button>
                                        {isExpanded ? <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 12C5.25 11.5858 5.58579 11.25 6 11.25H12H18C18.4142 11.25 18.75 11.5858 18.75 12C18.75 12.4142 18.4142 12.75 18 12.75H12H6C5.58579 12.75 5.25 12.4142 5.25 12Z" fill="#2D7A31"/>
                                            </svg>
                                            : <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 5.25C12.4142 5.25 12.75 5.58579 12.75 6V11.25H18C18.4142 11.25 18.75 11.5858 18.75 12C18.75 12.4142 18.4142 12.75 18 12.75H12.75V18C12.75 18.4142 12.4142 18.75 12 18.75C11.5858 18.75 11.25 18.4142 11.25 18V12.75H6C5.58579 12.75 5.25 12.4142 5.25 12C5.25 11.5858 5.58579 11.25 6 11.25H11.25V6C11.25 5.58579 11.5858 5.25 12 5.25Z" fill="#2D7A31"/>
                                            </svg>
                                        }
                                    </h2>
                                </div>

                                <div id={`collapse${index}`} className="collapse" aria-labelledby="headingOne" data-parent="#accordionFaq">
                                    <div className="card-body">
                                        <p>{item.description}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    );
};

Assistance.propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired
};

export default Accordion;
