import axios from "axios";
import {
    GET_MEDIA_TYPE_URL,
    GET_PRE_SIGNED_URL,
    JOB_APPLY_URL,
    GET_MEDIA_URL,
    SUBSCRIBE_URL,
    GET_MEDIA_PRIORITY_URL
} from "./index";
import errorHandler from "../utils/errorHandler";

export const getMedia = () => {
    return axios.all([
        axios.get(GET_MEDIA_TYPE_URL("press", 0)),
        axios.get(GET_MEDIA_TYPE_URL("blog", 0)),
        axios.get(GET_MEDIA_PRIORITY_URL)
    ]).then(axios.spread(async(response1, response2, response3) => {
        // Multiple requests are now complete
        return {
            press: response1.data.payload,
            blog: response2.data.payload,
            priority: response3.data.payload
        }
    })).catch((error) => {
        errorHandler(error)
    });
};

export const getMediaData = ({params}) => {
    let {id} = params;
    return axios.get(GET_MEDIA_URL(id)).then((response) => {
        return {
            media: response.data.payload
        }
    }).catch((error) => {
        errorHandler(error)
    });
}

export const getMediaType = ({params}) => {
    let {type} = params;
    return axios.get(GET_MEDIA_TYPE_URL(type, 0)).then((response) => {
        return {
            media: response.data.payload,
            title: type === "press" ? "Press Releases" : "Blog"
        }
    }).catch((error) => {
        errorHandler(error)
    });
};

export const subscribe = (data) => {
    return axios.post(SUBSCRIBE_URL, data).then((response) => {
        return {
            payload: response.data
        }
    }).catch((error) => {
        errorHandler(error)
    });
};

export const getPreSignedUrl = () => {
    return axios.get(GET_PRE_SIGNED_URL).then((response) => {
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        errorHandler(error);
        return {
            payload: error,
            error: true
        }
    });
}

export const S3Upload = (signed_url, binary, config) => {
    return axios.put(signed_url, binary, config).then((response) => {
        return {
            payload: response,
            error: false
        }
    }).catch((error) => {
        errorHandler(error);
        return {
            payload: error,
            error: true
        }
    });
}

export const applyForm = (method, data) => {
    return axios({method: method, url: JOB_APPLY_URL, data}).then((response) => {
        return {
            payload: response.data.message,
            error: false
        }
    }).catch((error) => {
        errorHandler(error);
        return {
            payload: null,
            error: true
        }
    });
};
