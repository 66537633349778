import {createSlice} from '@reduxjs/toolkit';

const slice = createSlice({
    name: "app",
    initialState: {
        session: null
    },
    reducers: {
        //actions => action handlers
        SET_SESSION: (state, action) => {
            return {
                ...state,
                session: action.payload.session
            };
        }
    }
});

export const {SET_SESSION} = slice.actions;
export default slice.reducer;
