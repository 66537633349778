import React from 'react';
import Header from "../../components/layout/Header";
import Footer from "../../components/layout/Footer";
import SideHeader from "../../components/layout/SideHeader";
import Accordion from "../../components/layout/Accordion";
import faq from "../../json/faq.json";
import AppDownload from "../../components/layout/AppDownload";

const PlasticCredit = () => {
    return (
        <>
            <Header/>
            <SideHeader
                title="Plastic Credit"
                subtitle="Empowering Manufacturers for Sustainable Production"
                description="Welcome to Ebusaka's Plastic Credit page, where we offer a groundbreaking solution for manufacturers to offset their plastic footprint and promote sustainable production practices. With Ebusaka's Plastic Credit program, manufacturing companies can take a proactive step towards environmental responsibility while contributing to social and economic development."
                img_src={"/assets/resources/plastic-credit.jpeg"}
            />

            <section id="gl-content">
                <div className="container">
                    <div className="row" style={{marginBottom: 48}}>
                        <h1>Understanding Plastic Credits</h1>
                        <p>Plastic Credits are a unique concept that enables manufacturing companies to balance their plastic usage by supporting responsible waste management and recycling initiatives. For every kilogram of plastic used in production, businesses can purchase an equivalent amount of Plastic Credits from Ebusaka.</p>
                    </div>

                    <div className="row">
                        <h1>Making a Positive Impact</h1>
                        <p>By purchasing Plastic Credits, manufacturing companies actively support Ebusaka's initiatives to empower low-income communities, particularly women and youth, in collecting and recycling plastic waste from the environment. This not only helps clean up the surroundings but also creates livelihood opportunities for marginalized groups.</p>
                    </div>
                </div>
            </section>

            <section className="core" style={{paddingBottom: 16}}>
                <div className="container">
                    <div className="row">
                        <div className="col-4">
                            <img src="/assets/resources/plastic-green.jpg" alt="outgrow" className="img-fluid"/>
                        </div>

                        <div className="col-8 my-auto">
                            <h3>Benefits of Plastic Credits</h3>
                            <ul className="list-unstyled">
                                <li><span>Environmental Responsibility</span><br/> Demonstrate your commitment to reducing plastic waste and promoting sustainable production practices.</li>
                                <li><span>Plastic Neutral Status</span><br/> Offset the amount of plastic used in manufacturing by investing in Plastic Credits, achieving a plastic-neutral status.</li>
                                <li><span>Positive Social Impact</span><br/> Contribute to the social and economic well-being of communities by empowering them through waste collection and recycling.</li>
                                <li><span>Public Recognition</span><br/> Showcase your company's environmental stewardship and sustainability efforts to customers, investors, and stakeholders.</li>
                                <li><span>Circular Economy Advancement</span><br/> Become part of the circular economy movement by actively participating in responsible plastic management.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section id="gl-content" style={{background: "white"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 my-auto">
                            <h1>Transparent and Accountable</h1>
                            <p>Ebusaka's Plastic Credit program operates with full transparency and accountability. Every Plastic Credit purchase is meticulously documented, and the impact is tracked, ensuring that your investment directly supports impactful projects and initiatives.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section id="plastic-credit">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-7">
                            <h1>How It Work</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card-block text-center">
                                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M36.9331 10.5802H7.06445L8.53845 3.20103C8.61928 2.80376 8.83505 2.44669 9.14916 2.19038C9.46327 1.93408 9.85638 1.79434 10.2618 1.79486H33.734C34.1394 1.79434 34.5325 1.93408 34.8466 2.19038C35.1607 2.44669 35.3765 2.80376 35.4573 3.20103L36.9331 10.5802Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M32.5423 10.5802H11.459L12.3372 5.30933H31.6642L32.5423 10.5802Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M33.6111 40.6652C33.5583 41.0903 33.352 41.4816 33.0309 41.7652C32.7098 42.0488 32.2961 42.2053 31.8676 42.2052H11.3013C10.8624 42.2071 10.4387 42.0446 10.1137 41.7497C9.78861 41.4549 9.58572 41.049 9.54495 40.612L7.06445 14.0947H36.9331L33.6111 40.6652Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M40.4496 12.3365C40.4496 12.8023 40.2646 13.2491 39.9352 13.5785C39.6058 13.9078 39.1591 14.0929 38.6933 14.0929H5.31194C4.84589 14.0929 4.39892 13.9077 4.06937 13.5782C3.73983 13.2486 3.55469 12.8017 3.55469 12.3356C3.55469 11.8696 3.73983 11.4226 4.06937 11.0931C4.39892 10.7635 4.84589 10.5784 5.31194 10.5784H38.6933C39.1591 10.5784 39.6058 10.7634 39.9352 11.0928C40.2646 11.4222 40.4496 11.8707 40.4496 12.3365Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M33.6111 40.6652C33.5583 41.0903 33.352 41.4816 33.0309 41.7652C32.7098 42.0488 32.2961 42.2053 31.8676 42.2052H11.3013C10.8624 42.2071 10.4387 42.0446 10.1137 41.7497C9.78861 41.4549 9.58572 41.049 9.54495 40.612L7.06445 14.0947H36.9331L33.6111 40.6652Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M18.0454 29.282L16.7896 26.6035L13.6289 27.2818" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M18.3092 33.3886H15.2952C15.0453 33.3902 14.7994 33.3255 14.5826 33.2012C14.3658 33.077 14.1857 32.8975 14.0608 32.6811C13.9358 32.4647 13.8704 32.219 13.8711 31.9691C13.8718 31.7192 13.9387 31.474 14.065 31.2583L16.7783 26.6053" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M24.8439 31.2108L22.9336 33.5794L24.8439 36.2194" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M27.7026 28.2663L29.5561 31.4746C29.6808 31.6903 29.7465 31.935 29.7465 32.1842C29.7465 32.4333 29.6808 32.6781 29.556 32.8937C29.4313 33.1094 29.2519 33.2884 29.0359 33.4126C28.8199 33.5368 28.575 33.6019 28.3259 33.6013L22.9414 33.5793" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M22.7344 24.4584L25.9005 24.7683L27.2572 22.1558" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M19.0664 23.0322L20.7696 20.086C20.8932 19.8688 21.0721 19.6881 21.2882 19.5625C21.5043 19.4368 21.7498 19.3706 21.9997 19.3706C22.2497 19.3706 22.4952 19.4368 22.7113 19.5625C22.9274 19.6881 23.1063 19.8688 23.2299 20.086L25.9011 24.761" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <h2>Purchase Plastic Credits</h2>
                                <p>Manufacturing companies can purchase Plastic Credits equivalent to the amount of plastic used in production.</p>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="card-block text-center">
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M30.707 39.0439V46.0419" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M30.707 39.0439L32.767 38.3579C33.5968 38.0592 34.4853 37.96 35.3605 38.0684C36.2358 38.1768 37.0732 38.4897 37.805 38.9819C38.7334 39.51 39.7575 39.8482 40.8179 39.9766C41.8782 40.105 42.9534 40.0212 43.981 39.7299L46.041 39.0439V29.1939C46.0413 29.0419 46.0053 28.892 45.9362 28.7566C45.867 28.6212 45.7667 28.5042 45.6433 28.4153C45.52 28.3264 45.3773 28.2681 45.2271 28.2453C45.0768 28.2224 44.9232 28.2356 44.779 28.2839L43.979 28.5499C41.8809 29.1106 39.6468 28.8315 37.751 27.7719C37.0155 27.3114 36.1872 27.0193 35.3256 26.9166C34.4639 26.8139 33.5902 26.9031 32.767 27.1779L30.707 27.8639V39.0439Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M1.95703 30.708C1.95703 27.658 3.16863 24.7329 5.3253 22.5763C7.48197 20.4196 10.407 19.208 13.457 19.208C16.507 19.208 19.4321 20.4196 21.5888 22.5763C23.7454 24.7329 24.957 27.658 24.957 30.708H1.95703Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M6.26953 9.14601C6.26953 10.0899 6.45545 11.0246 6.81669 11.8967C7.17792 12.7688 7.70738 13.5612 8.37485 14.2287C9.04232 14.8962 9.83471 15.4256 10.7068 15.7869C11.5789 16.1481 12.5136 16.334 13.4575 16.334C14.4015 16.334 15.3362 16.1481 16.2083 15.7869C17.0803 15.4256 17.8727 14.8962 18.5402 14.2287C19.2077 13.5612 19.7371 12.7688 20.0984 11.8967C20.4596 11.0246 20.6455 10.0899 20.6455 9.14601C20.6455 7.23963 19.8882 5.41134 18.5402 4.06332C17.1922 2.71531 15.3639 1.95801 13.4575 1.95801C11.5512 1.95801 9.72286 2.71531 8.37485 4.06332C7.02684 5.41134 6.26953 7.23963 6.26953 9.14601Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M24.4805 9.14601C24.4805 11.0524 25.2378 12.8807 26.5858 14.2287C27.9338 15.5767 29.7621 16.334 31.6685 16.334C33.5748 16.334 35.4031 15.5767 36.7512 14.2287C38.0992 12.8807 38.8565 11.0524 38.8565 9.14601C38.8565 7.23963 38.0992 5.41134 36.7512 4.06332C35.4031 2.71531 33.5748 1.95801 31.6685 1.95801C29.7621 1.95801 27.9338 2.71531 26.5858 4.06332C25.2378 5.41134 24.4805 7.23963 24.4805 9.14601Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M40.2441 23.0421C38.4837 21.0702 36.0977 19.7649 33.4877 19.346C30.8777 18.9271 28.2031 19.4201 25.9141 20.7421" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <h2>Empowering Communities</h2>
                                <p>The funds from Plastic Credit purchases are channeled towards empowering local communities for plastic waste collection and recycling.</p>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="card-block text-center">
                                <svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M24.7864 23.61C24.772 24.2378 24.8833 24.8621 25.1136 25.4463C25.344 26.0304 25.6887 26.5627 26.1276 27.0117C26.5665 27.4608 27.0908 27.8176 27.6695 28.0612C28.2483 28.3048 28.8699 28.4303 29.4978 28.4303C30.1257 28.4303 30.7473 28.3048 31.3261 28.0612C31.9048 27.8176 32.429 27.4608 32.8679 27.0117C33.3069 26.5627 33.6516 26.0304 33.8819 25.4463C34.1123 24.8621 34.2235 24.2378 34.2092 23.61C34.2235 22.9823 34.1123 22.358 33.8819 21.7738C33.6516 21.1897 33.3069 20.6574 32.8679 20.2084C32.429 19.7593 31.9048 19.4025 31.3261 19.1589C30.7473 18.9153 30.1257 18.7898 29.4978 18.7898C28.8699 18.7898 28.2483 18.9153 27.6695 19.1589C27.0908 19.4025 26.5665 19.7593 26.1276 20.2084C25.6887 20.6574 25.344 21.1897 25.1136 21.7738C24.8833 22.358 24.772 22.9823 24.7864 23.61Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M38.9223 37.7457C38.385 35.6545 37.1674 33.8014 35.4613 32.4782C33.7551 31.155 31.6574 30.4369 29.4982 30.4369C27.3391 30.4369 25.2413 31.155 23.5352 32.4782C21.8291 33.8014 20.6115 35.6545 20.0742 37.7457H38.9223Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M25.968 5.94202C25.9556 6.41393 26.0378 6.88356 26.2098 7.32319C26.3818 7.76283 26.6401 8.16355 26.9695 8.50174C27.2988 8.83992 27.6926 9.10871 28.1276 9.29223C28.5625 9.47576 29.0298 9.57032 29.5019 9.57032C29.974 9.57032 30.4413 9.47576 30.8762 9.29223C31.3111 9.10871 31.7049 8.83992 32.0343 8.50174C32.3637 8.16355 32.622 7.76283 32.794 7.32319C32.966 6.88356 33.0482 6.41393 33.0357 5.94202C33.0482 5.4701 32.966 5.00047 32.794 4.56084C32.622 4.12121 32.3637 3.72048 32.0343 3.3823C31.7049 3.04412 31.3111 2.77533 30.8762 2.5918C30.4413 2.40827 29.974 2.31372 29.5019 2.31372C29.0298 2.31372 28.5625 2.40827 28.1276 2.5918C27.6926 2.77533 27.2988 3.04412 26.9695 3.3823C26.6401 3.72048 26.3818 4.12121 26.2098 4.56084C26.0378 5.00047 25.9556 5.4701 25.968 5.94202Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M24.7871 14.1875C24.8152 12.9565 25.324 11.7855 26.2044 10.9249C27.0849 10.0643 28.2673 9.58246 29.4985 9.58246C30.7297 9.58246 31.9121 10.0643 32.7926 10.9249C33.6731 11.7855 34.1818 12.9565 34.2099 14.1875" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M48.3469 29.5004C48.3345 29.9723 48.4167 30.4419 48.5887 30.8815C48.7607 31.3212 49.019 31.7219 49.3484 32.0601C49.6777 32.3983 50.0715 32.6671 50.5065 32.8506C50.9414 33.0341 51.4087 33.1287 51.8808 33.1287C52.3529 33.1287 52.8202 33.0341 53.2551 32.8506C53.6901 32.6671 54.0838 32.3983 54.4132 32.0601C54.7426 31.7219 55.0009 31.3212 55.1729 30.8815C55.3449 30.4419 55.4271 29.9723 55.4146 29.5004C55.4271 29.0285 55.3449 28.5588 55.1729 28.1192C55.0009 27.6796 54.7426 27.2788 54.4132 26.9406C54.0838 26.6025 53.6901 26.3337 53.2551 26.1502C52.8202 25.9666 52.3529 25.8721 51.8808 25.8721C51.4087 25.8721 50.9414 25.9666 50.5065 26.1502C50.0715 26.3337 49.6777 26.6025 49.3484 26.9406C49.019 27.2788 48.7607 27.6796 48.5887 28.1192C48.4167 28.5588 48.3345 29.0285 48.3469 29.5004Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M47.1699 37.7458C47.198 36.5149 47.7068 35.3439 48.5873 34.4833C49.4678 33.6227 50.6501 33.1408 51.8813 33.1408C53.1125 33.1408 54.2949 33.6227 55.1754 34.4833C56.0559 35.3439 56.5646 36.5149 56.5927 37.7458" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M25.968 48.3483C25.9556 48.8202 26.0378 49.2898 26.2098 49.7294C26.3818 50.1691 26.6401 50.5698 26.9695 50.908C27.2988 51.2462 27.6926 51.515 28.1276 51.6985C28.5625 51.882 29.0298 51.9766 29.5019 51.9766C29.974 51.9766 30.4413 51.882 30.8762 51.6985C31.3111 51.515 31.7049 51.2462 32.0343 50.908C32.3637 50.5698 32.622 50.1691 32.794 49.7294C32.966 49.2898 33.0482 48.8202 33.0357 48.3483C33.0482 47.8764 32.966 47.4067 32.794 46.9671C32.622 46.5275 32.3637 46.1267 32.0343 45.7886C31.7049 45.4504 31.3111 45.1816 30.8762 44.9981C30.4413 44.8145 29.974 44.72 29.5019 44.72C29.0298 44.72 28.5625 44.8145 28.1276 44.9981C27.6926 45.1816 27.2988 45.4504 26.9695 45.7886C26.6401 46.1267 26.3818 46.5275 26.2098 46.9671C26.0378 47.4067 25.9556 47.8764 25.968 48.3483Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M24.7871 56.5937C24.8152 55.3628 25.324 54.1918 26.2044 53.3312C27.0849 52.4706 28.2673 51.9887 29.4985 51.9887C30.7297 51.9887 31.9121 52.4706 32.7926 53.3312C33.6731 54.1918 34.1818 55.3628 34.2099 56.5937" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M3.58326 29.5004C3.57081 29.9723 3.65301 30.4419 3.82501 30.8815C3.997 31.3212 4.25531 31.7219 4.58469 32.0601C4.91407 32.3983 5.30785 32.6671 5.74279 32.8506C6.17774 33.0341 6.64504 33.1287 7.11711 33.1287C7.58919 33.1287 8.05649 33.0341 8.49143 32.8506C8.92638 32.6671 9.32016 32.3983 9.64954 32.0601C9.97892 31.7219 10.2372 31.3212 10.4092 30.8815C10.5812 30.4419 10.6634 29.9723 10.651 29.5004C10.6634 29.0285 10.5812 28.5588 10.4092 28.1192C10.2372 27.6796 9.97892 27.2788 9.64954 26.9406C9.32016 26.6025 8.92638 26.3337 8.49143 26.1502C8.05649 25.9666 7.58919 25.8721 7.11711 25.8721C6.64504 25.8721 6.17774 25.9666 5.74279 26.1502C5.30785 26.3337 4.91407 26.6025 4.58469 26.9406C4.25531 27.2788 3.997 27.6796 3.82501 28.1192C3.65301 28.5588 3.57081 29.0285 3.58326 29.5004Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M2.40625 37.7458C2.43437 36.5149 2.94309 35.3439 3.82359 34.4833C4.70408 33.6227 5.88642 33.1408 7.11765 33.1408C8.34887 33.1408 9.53121 33.6227 10.4117 34.4833C11.2922 35.3439 11.8009 36.5149 11.829 37.7458" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M38.5938 13.8798C42.4935 15.7181 45.7403 18.7015 47.901 22.4323" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M40.8328 20.1463L38.5957 13.88L44.862 11.6429" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M17.25 23.0223L10.583 23.4451L10.1602 16.8002" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M18.2164 45.4966L19.9126 51.9522L13.457 53.6238" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M41.8203 43.6358L48.416 42.6229L49.4289 49.2186" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M10.6523 23.4454C12.699 19.3734 16.0032 16.0692 20.0751 14.0226" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M19.9111 51.9521C15.846 49.8959 12.5445 46.5944 10.4883 42.5293" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M48.3466 42.6229C46.2904 46.688 42.9889 49.9895 38.9238 52.0457" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <h2>Community-Focused</h2>
                                <p>The positive impact of Plastic Credit investments, such as waste collected and livelihoods supported, is regularly measured and reported.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="_gl-content" style={{background: "white"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div id="_gl-content-content-img">
                                <img src="/assets/resources/think-green.jpeg" className="img-fluid" alt="community people" />
                            </div>
                        </div>

                        <div className="col-md-6 my-auto">
                            <h1>Leading the Way Towards Sustainability</h1>
                            <p>By engaging in Ebusaka's Plastic Credit program, your manufacturing company becomes a leading force in promoting sustainable practices. Your commitment to environmental stewardship extends beyond your operations and contributes to a greener and more sustainable future for all.</p>
                        </div>
                    </div>
                </div>
            </section>

            <Accordion
                title="Frequently Asked Questions (FAQs)"
                data={faq.support}
            />

            <AppDownload
                img_src="/assets/resources/app-download.png"
                title="Get Involved Today"
                description={
                    [
                        "Join the growing community of manufacturers that are taking proactive steps towards environmental responsibility. Contact Ebusaka to learn more about how Plastic Credits can benefit your company and support our collective journey towards a circular economy and a healthier planet. Together, let's make a positive impact on the environment and society while building a brighter future for generations to come."
                    ]
                }
            />

            <Footer/>
        </>
    );
};

export default PlasticCredit;
