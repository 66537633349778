import React from 'react';
import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <img src="/assets/resources/w-logo.svg" alt="logo" />

                <div className="row">
                    <div className="col-md-3">
                        <h5>Products &amp; Services</h5>
                        <ul className="list-unstyled" style={{width: "90%"}}>
                            <li><Link to="/home/residential-waste-pickup">Trash Collection & Recycling for Home</Link></li>
                            <li><Link to="/business/business-waste-pickup">Waste Disposal & Recycling for Business</Link></li>
                            <li><Link to="/home/bulk-trash-pickup">Bulk Trash Pickup</Link></li>
                            <li><Link to="/business/plastic-credit">Plastic Credit</Link></li>
                        </ul>
                    </div>

                    <div className="col-md-3 d-flex justify-content-center">
                        <div>
                            <h5>Our Company</h5>
                            <ul className="list-unstyled">
                                <li><Link to="/insider/who-we-are">Who We Are</Link></li>
                                <li><Link to="/insider/story">Our Story</Link></li>
                                <li><Link to="/insider/team">Our Teams</Link></li>
                                <li><Link to="/insider/career">Careers</Link></li>
                                <li><Link to="/insider/partner">Partners</Link></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-md-3 d-flex justify-content-center">
                        <div>
                            <h5>Quick Link</h5>
                            <ul className="list-unstyled">
                                <li><Link to="/drop-off-location">Drop Off Location</Link></li>
                                <li><Link to="/">Frequently Asked Question</Link></li>
                                <li><Link to="/media-room">News & Media</Link></li>
                                <li><Link to="/drop-off-location">Drop Off Location</Link></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-md-3 d-flex justify-content-center">
                        <div>
                            <h5>Social Link</h5>
                            <ul className="list-unstyled">
                                <li><a href="https://web.facebook.com/ebusakazm" target="_blank"><i className="fa fa-facebook"></i>Facebook</a></li>
                                <li><a href="https://twitter.com/Ebusaka_ZM" target="_blank"><i className="fa fa-twitter"></i>Twitter</a></li>
                                <li><a href="https://www.instagram.com/ebusaka_zm" target="_blank"><i className="fa fa-instagram"></i>Instagram</a></li>
                                <li><a href="https://www.linkedin.com/company/ebusaka" target="_blank"><i className="fa fa-linkedin"></i>Linkedin</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div id="sub-footer">
                <div className="container">
                    <div className="d-flex justify-content-between">
                        <p>©{new Date().getFullYear()} Ebusaka Green Technology Limited.</p>
                        <ul className="list-unstyled">
                            <li className="list-inline-item"><Link to="/privacy">Privacy Policy</Link></li>
                            <li className="list-inline-item text-white">|</li>
                            <li className="list-inline-item"><Link to="/term-of-use">Terms Of Use</Link></li>
                        </ul>
                    </div>

                    <span>Ebusaka Green Technology, is Zambia leading provider of comprehensive environmental solutions. Ebusaka is always Creating a Greener, Cleaner, and More Sustainable Future Together.</span>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
