import React from 'react';
import {Helmet} from "react-helmet";
import PropTypes from "prop-types";

const SEO = (props) => {

    let {title, description} = props;

    return (
        <Helmet>
            <html lang="en" />
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="theme-color" content="#70A12F" />
        </Helmet>
    );
};

SEO.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default SEO;
