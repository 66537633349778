import React from 'react';
import {Link} from "react-router-dom";

const ErrorPage = () => {
    return (
        <div id="error" className="container text-center">
            <div className="mt-5">
                <svg width="250" height="250" viewBox="0 0 140 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M123 55.7215C123 69.9215 117.2 82.7215 107.9 91.9215C98.8003 101.022 86.2003 106.522 72.3003 106.522C58.5003 106.522 45.9003 100.922 36.7003 91.9215C27.4003 82.7215 21.6003 69.9215 21.6003 55.7215C21.6003 27.6215 44.3003 4.92151 72.3003 4.92151C100.3 4.92151 123 27.7215 123 55.7215Z" fill="#EAEEF9"/>
                    <path d="M118.6 22.4215C120.865 22.4215 122.7 20.5859 122.7 18.3215C122.7 16.0571 120.865 14.2215 118.6 14.2215C116.336 14.2215 114.5 16.0571 114.5 18.3215C114.5 20.5859 116.336 22.4215 118.6 22.4215Z" fill="#EAEEF9"/>
                    <path d="M124.6 6.42153C126.147 6.42153 127.4 5.16793 127.4 3.62153C127.4 2.07514 126.147 0.821533 124.6 0.821533C123.054 0.821533 121.8 2.07514 121.8 3.62153C121.8 5.16793 123.054 6.42153 124.6 6.42153Z" fill="#EAEEF9"/>
                    <path d="M23.2004 22.3215C24.7468 22.3215 26.0004 21.0679 26.0004 19.5215C26.0004 17.9751 24.7468 16.7215 23.2004 16.7215C21.654 16.7215 20.4004 17.9751 20.4004 19.5215C20.4004 21.0679 21.654 22.3215 23.2004 22.3215Z" fill="#EAEEF9"/>
                    <path d="M6.10039 76.3215C8.97227 76.3215 11.3004 73.9934 11.3004 71.1215C11.3004 68.2496 8.97227 65.9215 6.10039 65.9215C3.22851 65.9215 0.900391 68.2496 0.900391 71.1215C0.900391 73.9934 3.22851 76.3215 6.10039 76.3215Z" fill="#EAEEF9"/>
                    <g filter="url(#filter0_d_30_2012)">
                        <path d="M117.619 26.6042V82.009C117.619 84.315 115.773 86.1419 113.51 86.1419H30.0553C27.7925 86.1419 25.9167 84.2851 25.9167 82.009V26.6042C25.9167 24.3281 27.7627 22.4713 30.0553 22.4713H113.51C115.773 22.4713 117.619 24.3281 117.619 26.6042Z" fill="url(#paint0_linear_30_2012)"/>
                    </g>
                    <path d="M117.619 26.6042V32.8036H25.9167V26.6042C25.9167 24.3281 27.7627 22.4713 30.0553 22.4713H113.51C115.773 22.4713 117.619 24.3281 117.619 26.6042Z" fill="#D5DDEA"/>
                    <path d="M31.6928 29.1797C32.5478 29.1797 33.241 28.4825 33.241 27.6224C33.241 26.7623 32.5478 26.0651 31.6928 26.0651C30.8377 26.0651 30.1445 26.7623 30.1445 27.6224C30.1445 28.4825 30.8377 29.1797 31.6928 29.1797Z" fill="#989FB0"/>
                    <path d="M36.7543 29.1797C37.6093 29.1797 38.3025 28.4825 38.3025 27.6224C38.3025 26.7623 37.6093 26.0651 36.7543 26.0651C35.8992 26.0651 35.2061 26.7623 35.2061 27.6224C35.2061 28.4825 35.8992 29.1797 36.7543 29.1797Z" fill="#989FB0"/>
                    <path d="M41.786 29.1797C42.6411 29.1797 43.3342 28.4825 43.3342 27.6224C43.3342 26.7623 42.6411 26.0651 41.786 26.0651C40.931 26.0651 40.2378 26.7623 40.2378 27.6224C40.2378 28.4825 40.931 29.1797 41.786 29.1797Z" fill="#989FB0"/>
                    <path d="M45.061 70.5387H98.4746" stroke="#D5DDEA" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M53.4869 76.2589H90.0488" stroke="#D5DDEA" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M62.8358 54.4264V57.5111H60.9601V60.476H57.4468V57.5111H49.7057V53.9771L56.8811 43.9444H60.9898V54.4563H62.8358V54.4264ZM57.4468 48.0473L52.9808 54.4264H57.4468V48.0473Z" fill="url(#paint1_linear_30_2012)"/>
                    <path d="M71.6487 60.8054C67.8973 60.8054 64.5924 57.5111 64.5924 52.1802C64.5924 46.8494 67.8675 43.5551 71.6487 43.5551C75.43 43.5551 78.705 46.8494 78.705 52.1802C78.705 57.5111 75.43 60.8054 71.6487 60.8054ZM71.6487 57.6309C73.4054 57.6309 75.162 55.8639 75.162 52.2102C75.162 48.5565 73.4054 46.7895 71.6487 46.7895C69.8921 46.7895 68.1355 48.5565 68.1355 52.2102C68.1355 55.8639 69.8921 57.6309 71.6487 57.6309Z" fill="url(#paint2_linear_30_2012)"/>
                    <path d="M93.8597 54.4264V57.5111H91.984V60.476H88.4707V57.5111H80.7296V53.9771L87.905 43.9444H92.0138V54.4563H93.8597V54.4264ZM88.441 48.0473L83.9749 54.4264H88.441V48.0473Z" fill="url(#paint3_linear_30_2012)"/>
                    <path d="M113.564 98.3763L93.6174 78.4453L98.1902 73.8725L118.121 93.8193L113.564 98.3763Z" fill="#858B9B"/>
                    <path d="M120.498 105.389L105.203 90.0939C103.926 88.8167 103.926 86.7353 105.203 85.458C106.48 84.1808 108.562 84.1808 109.839 85.458L125.134 100.753C126.411 102.03 126.411 104.112 125.134 105.389C123.857 106.666 121.775 106.666 120.498 105.389Z" fill="#858B9B"/>
                    <path d="M119.41 106.367L99.7314 86.688C99.2741 86.2307 99.2741 85.4896 99.7314 85.0166L104.761 79.9865C105.219 79.5292 105.96 79.5292 106.433 79.9865L126.112 99.6652C126.569 100.123 126.569 100.864 126.112 101.337L121.082 106.367C120.608 106.824 119.867 106.824 119.41 106.367Z" fill="url(#paint4_linear_30_2012)"/>
                    <path d="M71.0373 14.2214C50.1602 14.2214 33.241 31.1407 33.241 52.0178C33.241 72.8791 50.1602 89.8142 71.0216 89.8142C91.8987 89.8142 108.818 72.8949 108.818 52.0178C108.818 31.1407 91.8987 14.2214 71.0373 14.2214ZM71.0373 82.8131C54.2757 82.8131 40.6993 69.0159 40.6993 52.002C40.6993 34.9882 54.2757 21.2067 71.0373 21.2067C87.7989 21.2067 101.375 35.0039 101.375 52.0178C101.375 69.0317 87.7832 82.8131 71.0373 82.8131Z" fill="url(#paint5_linear_30_2012)"/>
                    <defs>
                        <filter id="filter0_d_30_2012" x="3.91675" y="11.4713" width="135.702" height="107.671" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="11"/>
                            <feGaussianBlur stdDeviation="11"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_30_2012"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_30_2012" result="shape"/>
                        </filter>
                        <linearGradient id="paint0_linear_30_2012" x1="71.738" y1="20.9986" x2="71.738" y2="86.8283" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FDFEFF"/>
                            <stop offset="0.9964" stop-color="#ECF0F5"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_30_2012" x1="78.8652" y1="43.5551" x2="82.1644" y2="60.8054" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#B0BACC"/>
                            <stop offset="1" stop-color="#969EAE"/>
                        </linearGradient>
                        <linearGradient id="paint2_linear_30_2012" x1="78.8652" y1="43.5551" x2="82.1644" y2="60.8054" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#B0BACC"/>
                            <stop offset="1" stop-color="#969EAE"/>
                        </linearGradient>
                        <linearGradient id="paint3_linear_30_2012" x1="78.8652" y1="43.5551" x2="82.1644" y2="60.8054" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#B0BACC"/>
                            <stop offset="1" stop-color="#969EAE"/>
                        </linearGradient>
                        <linearGradient id="paint4_linear_30_2012" x1="99.376" y1="93.1796" x2="126.459" y2="93.1796" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#B0BACC"/>
                            <stop offset="1" stop-color="#969EAE"/>
                        </linearGradient>
                        <linearGradient id="paint5_linear_30_2012" x1="33.2064" y1="52.026" x2="108.832" y2="52.026" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#B0BACC"/>
                            <stop offset="1" stop-color="#969EAE"/>
                        </linearGradient>
                    </defs>
                </svg>
            </div>
            <h1>404 - Page Not Found</h1>
            <p>The Page you are looking for might be removed<br/>had its name change or temporarily unavailable</p>
            <Link to="/" className="btn btn-primary">Go to Homepage</Link>
        </div>
    );
};

export default ErrorPage;
