import React from 'react';
import Header from "../../components/layout/Header";
import Footer from "../../components/layout/Footer";
import CoverHeader from "../../components/layout/CoverHeader";

const Story = () => {
    return (
        <>
            <Header/>
            <CoverHeader
                title="Our Story"
                description="At Ebusaka, collaboration is at the heart of our journey towards transforming waste management in Africa. We believe that working hand-in-hand with stakeholders and partners is essential to achieve our mission of creating a cleaner and healthier environment. Our dedication to making a positive impact has led us to forge meaningful partnerships with various entities."
                imguri="../../assets/resources/truck.jpeg"
            />
            <section id="story">
                <div className="container">
                    <div className="mt-5 mb-5">
                        <p>Thus, Ebusaka was born - a visionary green tech startup with a mission to transform waste management in Africa and beyond. Our story began with a deep-rooted desire to tackle the mounting waste challenges, ranging from plastic pollution to the outbreak of diseases like cholera, that were devastating our communities.</p>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-1">
                            <h1>1</h1>
                        </div>

                        <div className="col-12 col-md-4">
                            <h2>A Shared Vision for Change</h2>
                        </div>

                        <div className="col-12 col-md-7">
                            <p>We realized that the traditional waste management practices were no longer sustainable. With rapid urbanization and population growth, the burden of waste was increasing, posing serious threats to the environment and public health. It became evident that a paradigm shift was urgently needed, and we were driven by a shared vision to create a waste-free and sustainable world.</p>
                        </div>
                    </div>

                    <div className="row" style={{marginTop: 108}}>
                        <div className="col-12 col-md-1">
                            <h1>2</h1>
                        </div>

                        <div className="col-12 col-md-4">
                            <h2>Empowering Communities</h2>
                        </div>

                        <div className="col-12 col-md-7">
                            <p>At the core of our journey, we recognized that real change happens when communities are empowered. Ebusaka believes in the collective power of individuals, waste collectors, recyclers, and local governments to make a difference. We saw an opportunity to harness technology and innovation to connect all stakeholders and forge a united front against waste</p>
                        </div>
                    </div>

                    <div className="row" style={{marginTop: 108}}>
                        <div className="col-12 col-md-1">
                            <h1>3</h1>
                        </div>

                        <div className="col-12 col-md-4">
                            <h2>Accountability and Transparency</h2>
                        </div>

                        <div className="col-12 col-md-7">
                            <p>Our story also underscored the importance of accountability and transparency in waste management. By leveraging technology, we aimed to bring transparency to the entire waste management process, from collection to recycling. Through real-time tracking, waste collectors' ratings, and user feedback, we sought to ensure the highest standards of service delivery.</p>
                        </div>
                    </div>
                </div>

                <div id="hist-image">
                    <div className="container-fluid" style={{padding: 0}}>
                        <div className="row">
                            <div className="col-12 col-md-6" style={{padding: 0}}>
                                <div className="hist-image-contain">
                                    <img className="img-fluid" src="/assets/resources/dumpster.jpg" alt="truck" />
                                </div>
                            </div>

                            <div className="col-12 col-md-4" style={{padding: 0}}>
                                <div className="hist-image-contain">
                                    <img className="img-fluid" src="/assets/resources/bin.jpg" alt="truck" />
                                </div>
                            </div>

                            <div className="col-12 col-md-2" style={{padding: 0}}>
                                <div className="hist-image-contain">
                                    <img className="img-fluid" src="/assets/resources/plastic-wrap.jpg" alt="truck" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-1">
                            <h1>4</h1>
                        </div>

                        <div className="col-12 col-md-4">
                            <h2>Investing in a Sustainable Future</h2>
                        </div>

                        <div className="col-12 col-md-7">
                            <p>As our story unfolded, we found ourselves drawn to a bigger purpose – not just for ourselves but for future generations. Ebusaka's commitment to sustainability is unwavering. We focus on turning waste into resources, promoting circular economy principles, and actively contributing to the United Nations' Sustainable Development Goals.</p>
                        </div>
                    </div>

                    <div className="row" style={{marginTop: 108}}>
                        <div className="col-12 col-md-1">
                            <h1>5</h1>
                        </div>

                        <div className="col-12 col-md-4">
                            <h2>Attracting Like-Minded Partners</h2>
                        </div>

                        <div className="col-12 col-md-7">
                            <p>Our story is also a testament to the power of collaboration. We understand that solving the waste crisis requires a united effort. We have forged partnerships with like-minded individuals, organizations, and governments to amplify our impact and drive positive change on a larger scale.</p>
                        </div>
                    </div>

                    <div className="row" style={{marginTop: 108}}>
                        <div className="col-12 col-md-1">
                            <h1>6</h1>
                        </div>

                        <div className="col-12 col-md-4">
                            <h2>A Journey to Success</h2>
                        </div>

                        <div className="col-12 col-md-7">
                            <p>Today, Ebusaka stands tall as a symbol of hope and change in the waste management landscape. We are driven by our collective passion for environmental responsibility, innovation, and community empowerment. Our story continues to evolve as we strive to make waste management a seamless and sustainable process for all.</p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    );
};

export default Story;
