import React from 'react';
import Header from "../../components/layout/Header";
import Footer from "../../components/layout/Footer";
import SubHeader from "../../components/layout/SubHeader";

const Award = () => {
    return (
        <>
            <Header/>
                <SubHeader
                    title="Awards and Recognitions"
                    description="At Ebusaka, our unwavering dedication to transforming waste management and promoting environmental sustainability has been recognized through numerous awards and accolades. These achievements are a testament to our commitment to making a positive impact on our planet and society. We take great pride in sharing some of the prestigious awards and recognitions that we have earned on our journey towards a greener future"
                />
                <section id="award">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <div className="award-img-contain">
                                    <img src="/assets/resources/award-mtn.jpg" className="img-fluid" alt="mtn-award" />
                                </div>
                            </div>

                            <div className="col-12 col-md-6 my-auto">
                                <h1>MTN Zambia Momo Hackathon</h1>
                                <h3>Awards: 1st Prize</h3>
                                <p>Ebusaka emerged as the winner of the MTN Zambia Momo Hackathon, showcasing our cutting-edge technology and its potential to revolutionize waste management.</p>
                            </div>
                        </div>

                        <div className="row" style={{marginTop: 100}}>
                            <div className="col-12 col-md-6 my-auto">
                                <h1>UNDP Innovation Challenge on Sustainable Waste Management and Recycling</h1>
                                <h3>Awards: Winner</h3>
                                <p>Our innovative waste management platform was recognized by the United Nations Development Programme (UNDP) as a pioneering solution in promoting sustainable waste management and recycling practices.</p>
                            </div>

                            <div className="col-12 col-md-6">
                                <div className="award-img-contain">
                                    <img src="/assets/resources/award-undp.jpg" className="img-fluid" alt="mtn-award" />
                                </div>
                            </div>
                        </div>

                        <div className="row" style={{marginTop: 100}}>
                            <div className="col-12 col-md-6">
                                <div className="award-img-contain">
                                    <img src="/assets/resources/award-ags.jpg" className="img-fluid" alt="mtn-award" />
                                </div>
                            </div>

                            <div className="col-12 col-md-6 my-auto">
                                <h1>AGS Programmes Pitching Programme</h1>
                                <h3>Awards: Recipient</h3>
                                <p>Our pitch for Ebusaka's waste management platform impressed the Accelerated Growth for SMEs in Zambia (AGS) Programme, earning us recognition and support for our vision.</p>
                            </div>
                        </div>

                        <div className="row" style={{marginTop: 100}}>
                            <div className="col-12 col-md-6 my-auto">
                                <h1>ZICTA Innovation Challenge</h1>
                                <h3>Awards: Finalist</h3>
                                <p>Ebusaka was selected as one of the top 60 innovations in the prestigious ZICTA Innovation Challenge, highlighting our innovative approach to waste management.</p>
                            </div>

                            <div className="col-12 col-md-6">
                                <div className="award-img-contain">
                                    <img src="/assets/resources/award-zicta.jpg" className="img-fluid" alt="mtn-award" />
                                </div>
                            </div>
                        </div>

                        <div className="row" style={{marginTop: 100}}>
                            <div className="col-12 col-md-6">
                                <div className="award-img-contain">
                                    <img src="/assets/resources/award-slush.jpg" className="img-fluid" alt="mtn-award" />
                                </div>
                            </div>

                            <div className="col-12 col-md-6 my-auto">
                                <h1>Slush Helsinki Finland</h1>
                                <h3>Awards: Recipient</h3>
                                <p>Our pitch for Ebusaka's waste management platform impressed the Accelerated Growth for SMEs in Zambia (AGS) Programme, earning us recognition and support for our vision.</p>
                            </div>
                        </div>
                    </div>
                </section>
            <Footer/>
        </>
    );
};

export default Award;
