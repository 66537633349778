import React, {useEffect} from 'react';
import { Provider } from 'react-redux';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider
} from 'react-router-dom';
import store from './stores';
import Home from "./pages/Home";
import Team from "./pages/insider/Team";
import Support from "./pages/Support";
import DropOffLocation from "./pages/DropOffLocation";
import MediaPage from "./pages/media/Page";
import {getMedia, getMediaData, getMediaType} from "./api/app";
import MediaRoom from "./pages/media";
import MediaList from "./pages/media/List";
import Privacy from "./pages/Privacy";
import TermOfUse from "./pages/TermOfUse";
import Career from "./pages/insider/Career";
import Award from "./pages/insider/Award";
import Partner from "./pages/insider/Partner";
import Story from "./pages/insider/Story";
import WhoWeAre from "./pages/insider/WhoWeAre";
import SocialImpact from "./pages/insider/SocialImpact";
import EconomicImpact from "./pages/insider/EconomicImpact";
import ErrorPage from "./components/layout/ErrorPage";
import ResidentialWaste from "./pages/home/ResidentialWaste";
import TradeWaste from "./pages/home/TradeWaste";
import BulkTrashPickUp from "./pages/home/BulkTrashPickUp";
import BusinessWaste from "./pages/business/BusinessWaste";
import Recycler from "./pages/business/Recycler";
import PlasticCredit from "./pages/business/PlasticCredit";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import ResidentialService from "./pages/home/ResidentialService";
import 'bootstrap';

const scrollToTop = () => {
    window.scrollTo(0, 0);
}

const JSXRouter = createBrowserRouter(
    createRoutesFromElements(
        <Route>
            <Route path="/" element={<Home />} />
            <Route path="/support" element={<Support />} />
            <Route path="/drop-off-location" element={<DropOffLocation />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/term-of-use" element={<TermOfUse />} />
            <Route path="/home">
                <Route path="residential-waste-pickup" element={<ResidentialWaste />} />
                <Route path="residential-services" element={<ResidentialService />} />
                <Route path="bulk-trash-pickup" element={<BulkTrashPickUp />} />
                <Route path="trade-waste" element={<TradeWaste />} />
            </Route>
            <Route path="/business">
                <Route path="business-waste-pickup" element={<BusinessWaste />} />
                <Route path="plastic-credit" element={<PlasticCredit />} />
                <Route path="recycler" element={<Recycler />} />
            </Route>
            <Route path="/media-room">
                <Route index loader={getMedia} element={<MediaRoom />} />
                <Route path=":id" loader={getMediaData} element={<MediaPage />} />
                <Route path="filter/:type" loader={getMediaType} element={<MediaList />} />
            </Route>
            <Route path="/insider">
                <Route path="who-we-are" element={<WhoWeAre />} />
                <Route path="story" element={<Story />} />
                <Route path="team" element={<Team />} />
                <Route path="partner" element={<Partner />} />
                <Route path="award" element={<Award />} />
                <Route path="career" element={<Career />} />
                <Route path="social-impact" element={<SocialImpact/>} />
                <Route path="economic-impact" element={<EconomicImpact/>} />
            </Route>
            <Route path="*" element={<ErrorPage/>} />
        </Route>
    )
)

const App = () => {
    useEffect(() => {
        const firebaseConfig = {
            apiKey: "AIzaSyDq_jcaXjdd9vXLTrNO4nTKdD-IEhysMhI",
            authDomain: "ebusaka-web.firebaseapp.com",
            projectId: "ebusaka-web",
            storageBucket: "ebusaka-web.appspot.com",
            messagingSenderId: "328531796759",
            appId: "1:328531796759:web:fb64ecd9246c4d651ccd52",
            measurementId: "G-NYDCE9LGM7"
        };

        // Initialize Firebase
        const app = initializeApp(firebaseConfig);
        getAnalytics(app);

        const cleanup = JSXRouter.subscribe(scrollToTop);
        return () => {
            cleanup();
        };
    }, []);

    return (
        <Provider store={store}>
            <RouterProvider router={JSXRouter} />
        </Provider>
    );
}

export default App;
