import React from 'react';
import Header from "../../components/layout/Header";
import Footer from "../../components/layout/Footer";
import SideHeader from "../../components/layout/SideHeader";
import {Link} from "react-router-dom";
import Accordion from "../../components/layout/Accordion";
import faq from "../../json/faq.json";
import AppDownload from "../../components/layout/AppDownload";

const BusinessWaste = () => {
    return (
        <>
            <Header/>
            <SideHeader
                title="Business Waste and Recycling Pickup"
                subtitle="Your Partner in Cleanliness and Sustainability"
                description="Welcome to Ebusaka's Business Waste and Recycling Pickup service, where businesses can conveniently manage their waste disposal needs with ease. Our platform offers a seamless and efficient way to request both once-off waste pickups and monthly garbage collection subscriptions."
                img_src={"/assets/resources/business-waste.jpeg"}
            />

            <section id="companion">
                <div className="container">
                    <div className="row">
                        <div className="col-4 text-center">
                            <img src='/assets/resources/mobile.jpg' className="img-fluid" alt="mobile-companion" />
                        </div>

                        <div className="col-8">
                            <ul>
                                <li><strong>Flexible Waste Pickup Options:</strong> With Ebusaka's app, businesses have the flexibility to choose between two convenient waste pickup options. Whether you require occasional waste removal for special events or a regular monthly subscription for ongoing waste management, our team is here to cater to your specific needs.</li>
                                <li><strong>Once-Off Waste Pickup:</strong> Organizing a corporate event, conference, or trade show? Ebusaka is your trusted partner for efficient once-off waste pickups. Simply use the app to request a pickup on the designated date, and our collectors will ensure your waste is responsibly handled and disposed of.</li>
                                <li><strong>Monthly Garbage Collection Subscription:</strong> For businesses seeking consistent waste management solutions, our monthly garbage collection subscription is the ideal choice. Enjoy hassle-free waste pickups scheduled according to your preferences, ensuring a clean and sustainable working environment for your company.</li>
                                <li><strong>Efficient and Reliable Service:</strong> At Ebusaka, we pride ourselves on providing efficient and reliable waste pickup services to businesses of all sizes. Our dedicated team ensures that your waste is collected promptly, leaving you to focus on your core business operations.</li>
                                <li><strong>Transparent and Convenient Payment:</strong> Ebusaka's app allows for seamless and transparent payment for waste pickups. Whether it's a one-time service or a monthly subscription, you can pay conveniently through mobile money, VISA, or Mastercard, ensuring a hassle-free experience.</li>
                                <li><strong>Tracking and Reporting:</strong> Our advanced app also provides businesses with the ability to track waste pickups and access detailed reports on waste generation and recycling rates. Stay informed about your waste management efforts and make data-driven decisions to improve sustainability.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section id="gl-content" style={{marginTop: 69}}>
                <div className="container">
                    <div className="row" style={{marginBottom: 68}}>
                        <h1>Sustainable Partnerships</h1>
                        <p>By choosing Ebusaka for your business waste and recycling needs, you join a network of like-minded businesses dedicated to environmental responsibility and sustainable practices. Together, we can make a significant impact on reducing waste and promoting a circular economy.</p>
                    </div>

                    <div className="row">
                        <h1>Empowering Local Communities</h1>
                        <p>When your business partners with Ebusaka, you support our initiatives to empower local communities, especially women and youth, through waste collection and recycling efforts. Your waste becomes a valuable resource that contributes to a greener and more inclusive society.</p>
                    </div>
                </div>
            </section>

            <section id="_gl-content" style={{background: "white"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div id="_gl-content-content-img">
                                <img src="/assets/resources/think-green.jpeg" className="img-fluid" alt="community people" />
                            </div>
                        </div>

                        <div className="col-md-6 my-auto">
                            <h1>Ebusaka's Green Vision</h1>
                            <p>At Ebusaka, we envision a future where waste is no longer viewed as a problem but as an opportunity for positive change. Join us on this green journey, and together, we can drive meaningful environmental and social impact while ensuring a sustainable future for generations to come.</p>
                        </div>
                    </div>
                </div>
            </section>

            <Accordion
                title="Frequently Asked Questions (FAQs)"
                data={faq.support}
            />

            <AppDownload
                img_src="/assets/resources/app-download.png"
                title="Contact Us Today"
                description={
                    [
                        "Take the first step towards responsible waste management and join Ebusaka's growing community of environmentally conscious businesses. Download our app or get in touch with our team to discuss how we can tailor our services to meet your specific waste management needs. Together, let's create a cleaner and greener tomorrow"
                    ]
                }
            />

            <Footer/>
        </>
    );
};

export default BusinessWaste;
