import React from 'react';
import Header from "../../components/layout/Header";
import Footer from "../../components/layout/Footer";
import CoverHeader from "../../components/layout/CoverHeader";

const SocialImpact = () => {
    return (
        <>
            <Header/>
            <CoverHeader
                title="Social Impact"
                description="At Ebusaka, we are committed to creating a positive social impact and fostering a more sustainable future. Our comprehensive waste management platform is strategically designed to directly address several United Nations Sustainable Development Goals (SDGs), aligning with global efforts to achieve a better and more sustainable world for all."
                imguri="../../assets/resources/hand-ground.jpeg"
            />
            <section id="sdg">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6" style={{marginBottom: 60}}>
                            <div className="block d-flex align-items-center" style={{background: "#F89D29"}}>
                                <img src="/assets/resources/sdg-11.png" className="img-fluid" alt="sdg - 11" />
                            </div>
                            <h2 style={{color: "#F89D29"}}>SDG 11 - Sustainable Cities and Communities</h2>
                            <p>Ebusaka's mission to promote responsible waste management practices contributes to building sustainable cities and communities. Through our platform, we strive to increase the proportion of urban solid waste that is collected and disposed of adequately. By doing so, we play a pivotal role in creating cleaner and healthier urban environments, enhancing the quality of life for residents.</p>
                        </div>

                        <div className="col-12 col-md-6">
                            <div className="block d-flex align-items-center" style={{background: "#BF8D2C"}}>
                                <img src="/assets/resources/sdg-12.png" className="img-fluid" alt="sdg - 12" />
                            </div>
                            <h2 style={{color: "#BF8D2C"}}>SDG 12 - Responsible Consumption and Production</h2>
                            <p>Central to Ebusaka's approach is our focus on responsible consumption and production, in line with SDG 12. Our platform encourages individuals and businesses to adopt sustainable consumption patterns. We incentivize recycling efforts, reduce waste generation, and foster the principles of a circular economy. By promoting mindful consumption, we contribute to a more efficient use of resources and a reduction in environmental impact.</p>
                        </div>

                        <div className="col-12 col-md-6">
                            <div className="block d-flex align-items-center" style={{background: "#407F46"}}>
                                <img src="/assets/resources/sdg-13.png" className="img-fluid" alt="sdg - 13" />
                            </div>
                            <h2 style={{color: "#407F46"}}>SDG 13 - Climate Action</h2>
                            <p>Ebusaka's dedication to reducing waste and increasing recycling rates aligns seamlessly with SDG 13 - Climate Action. Through our initiatives, we actively contribute to mitigating greenhouse gas emissions by diverting waste from landfills. By embracing sustainable waste management practices, we support global efforts to combat climate change and create a more sustainable planet for future generations.</p>
                        </div>

                        <div className="col-12 col-md-6">
                            <div className="block d-flex align-items-center" style={{background: "#12496B"}}>
                                <img src="/assets/resources/sdg-14.png" className="img-fluid" alt="sdg - 14" />
                            </div>
                            <h2 style={{color: "#12496B"}}>SDG 17 - Partnerships for the Goals</h2>
                            <p>Collaboration is at the heart of Ebusaka's approach. We recognize the power of partnerships in driving positive change. By working closely with waste collectors, recyclers, plastic producers, and municipal councils, we foster multi-stakeholder collaborations that align with SDG 17. Through these partnerships, we collectively work towards achieving sustainable waste management practices and promoting the broader agenda of the SDGs.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="impact">
                <div className="container">
                    <div>
                        <h1>Empowering Communities</h1>
                        <p>Our initiatives prioritize community empowerment, especially for women and youths from low-income backgrounds. By providing employment opportunities and entrepreneurship prospects, we enable communities to take ownership of waste management and improve their livelihoods.</p>
                    </div>

                    <div style={{marginTop: 66}}>
                        <h1>Promoting Environmental Education</h1>
                        <p>Environmental education is the cornerstone of driving behavior change. Through awareness campaigns and educational initiatives, we empower individuals, schools, and organizations with the knowledge and tools to embrace sustainable practices.</p>
                    </div>

                    <div style={{marginTop: 66}}>
                        <h1>Fostering Green Jobs</h1>
                        <p>Ebusaka's platform not only streamlines waste management but also creates green job opportunities for waste collectors and recyclers. These sustainable jobs support economic growth and help alleviate poverty.</p>
                    </div>

                    <div style={{marginTop: 66}}>
                        <h1>Continuous Innovation</h1>
                        <p>As part of our commitment to sustainable development, we actively collaborate with research institutions to study waste management trends and identify innovative solutions for a greener tomorrow</p>
                    </div>
                </div>
                <img src="/assets/resources/watermark.svg" alt="watermark"/>
            </section>

            <section className="impact" style={{background: "white"}}>
                <div className="container">
                    <div>
                        <p>Through real-life success stories, quantifiable impact metrics, and testimonies from beneficiaries, we highlight the tangible change Ebusaka is making in the lives of communities and the environment. Join us in our journey to build a world where waste is viewed as a resource, and sustainability becomes a way of life.</p>
                    </div>

                    <div style={{marginTop: 45}}>
                        <p>Ebusaka is more than just a waste management platform; it is a catalyst for social and environmental transformation. Together, we can drive sustainable change and leave a positive impact on the world for generations to come. Join us on this journey to create a cleaner, greener, and more sustainable future.</p>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    );
};

export default SocialImpact;
