import React from 'react';

const NoticeBoard = () => {
    return (
        <section className="container">
            <div id="noticeboard">
                <h5><strong>News Update:</strong> Lusaka Integrated Solid Waste Management Company Limited (LISWMC) Launches Ebusaka Digital Waste Management Pilot.</h5>
            </div>
        </section>
    );
};

export default NoticeBoard;
