import React from 'react';
import PropTypes from "prop-types";
import SEO from "./Seo";

const SubHeader = (props) => {
    return (
        <section id="subheader">
            <SEO
                title={props.title}
                description={props.description}
            />
            <div className="container">
                <h1>{props.title}</h1>
                <p>{props.description}</p>
            </div>
        </section>
    );
};

SubHeader.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default SubHeader;
