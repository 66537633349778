import React from 'react';
import PropTypes from "prop-types";
import SEO from "./Seo";

const SideHeader = (props) => {

    let {title, subtitle, description, img_src} = props;

    return (
        <section id="side-header" style={{position: "relative"}}>
            <SEO
                title={title}
                description={description}
            />
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6 my-auto">
                        <div style={{marginRight: 20}}>
                            <h1>{title}</h1>
                            <h3>{subtitle}</h3>
                            <p>{description}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12 col-md-6 p-0" style={{position: "absolute", top: 0, right: 0, bottom: 0}}>
                <div id="side-header-contain-img">
                    <img src={img_src} className="img-fluid" alt="residential waste pickup" />
                </div>
            </div>
        </section>
    );
};

SideHeader.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    img_src: PropTypes.string.isRequired
};

export default SideHeader;
