import React from 'react';
import Header from "../../components/layout/Header";
import Footer from "../../components/layout/Footer";
import NoticeBoard from "../../components/layout/NoticeBoard";
import {truncate} from "../../utils/trunate";
import {Link, useLoaderData} from "react-router-dom";
import MediaRow from "../../components/layout/MediaRow";
import Subscribe from "../../components/layout/Subscribe";
import SEO from "../../components/layout/Seo";

const MediaRoom = () => {

    const {press, blog, priority} = useLoaderData();

    return(
        <>
            <Header/>
            <SEO
                title="Media Room"
                description="Welcome to Ebusaka's News and Media hub, where we keep you updated on our latest developments, achievements, and contributions to the circular economy and environmental sustainability. Stay informed about our initiatives, events, and partnerships as we work towards creating a cleaner, greener future for Africa and beyond."
            />
            <section id="subheader">
                <div className="container">
                    <h1>MEDIA ROOM</h1>
                </div>
            </section>

            <NoticeBoard/>

            <section id="headline">
                <div className="container">
                    <div className="row">
                        <div className="col-6 my-auto">
                            <Link to={`/media-room/${priority._id}`} style={{textDecoration: "none"}}>
                                <h1>{truncate(priority.title, 60)}</h1>
                                <div className="desc" dangerouslySetInnerHTML={{__html: truncate(atob(priority.description), 400)}}/>
                            </Link>
                            <div className="d-flex align-items-center" style={{marginTop: 35}}>
                                <span>Aug 03, 2023</span>
                                <Link to="/media-room/filter/press">Press Release</Link>
                            </div>
                        </div>

                        <div className="col-6">
                            <Link to={`/media-room/${priority._id}`}>
                                <div className="headline-img-contain">
                                    <img src={priority.img_uri} alt="media img" className="img-fluid"/>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>

            <MediaRow
                title="Press Releases"
                route="/media-room/filter/press"
                data={press}
            />

            <MediaRow
                title="Blog"
                route="/media-room/filter/blog"
                data={blog}
                style={{marginTop: 110}}
            />

            <Subscribe/>

            <Footer/>
        </>
    );
};

export default MediaRoom;
