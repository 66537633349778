const errorHandler = (error) => {
    if(error.response) {
        switch (error.response.status) {
            case 401:
                throw new Error("Invalid Email address or password!");
            case 422:
                throw new Error(error.response.data['error'].details !== undefined ? error.response.data['error'].details[0].message : error.response.data['error']);
            default:
                throw new Error("Error 446: Something went wrong!.");
        }
    } else {
        throw new Error("Error 500: Server connection failed!.");
    }
};

export default errorHandler;
