import React from 'react';
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import {Link} from "react-router-dom";
import SEO from "../components/layout/Seo";

const TermOfUse = () => {
    return (
        <>
            <SEO
                title="Term Of Use"
                description="These Terms of Use describe the terms and conditions which you accept by using our Web App or our Services. We have incorporated by reference some linked information."
            />
            <Header/>
            <section id="term-of-use">
                <div className="container">
                    <h1>Term Of Use</h1>
                    <p>These Terms of Use describe the terms and conditions which you accept by using our Web App or our Services. We have incorporated by reference some linked information. </p>

                    <div className="mt-4">
                        <h2>Definition</h2>
                        <p><strong>"Account"</strong> means the account associated with your email address or number.</p>
                        <p><strong>"Inactive Account"</strong> means a User Account that has not been logged into for a 6-month period, or other period determined by us from time to time. </p>
                        <p><strong>"You or User"</strong> A user of the Ebusaka Web Application, including the service provider.</p>
                        <p><strong>"Ebusaka Web App or Our App or the App"</strong> The Ebusaka Web Application.</p>
                        <p><strong>"Service Provider"</strong> An independent person/body contracted to offer waste collection services to the users of the Ebusaka Web Application.</p>
                        <p><strong>"Terms"</strong> means these Terms of Use and all its counterparts including but not limited to the Privacy Policy.</p>
                    </div>

                    <div className="mt-4">
                        <h2>Overview</h2>
                        <p>By accessing the App, you agree to the following terms with Ebusaka.</p>
                        <p>Ebusaka Application is a virtual waste collection web application. The Services constitute a technology platform that enables users of the App to arrange and schedule waste collection with independent third-party service providers under agreement with Ebusaka.</p>
                        <p>We may amend these Terms and any linked information from time to time by posting amended terms on the Website, without notice to you.</p>
                        <p>Service Providers must register for an Account in order to provide waste collection services. The App enables Users to access waste collection service providers. Ebusaka’s role shall be limited to facilitating the engagement between the service provider and the User strictly as provided therein.</p>
                        <p>We may, from time to time, and without notice, change or add to the Web App or the information, products or services described in it. However, we do not undertake to keep the App updated. We are not liable to you or anyone else if any error occurs in the information on the Website or if that information is not current. </p>
                    </div>

                    <div className="mt-4">
                        <h2>Profile and Account</h2>
                        <p>The User will be required to provide a valid email and phone number to create an account. By using the App, you confirm that the information uploaded is a true and valid representation of your personal details.</p>
                        <p>Without limiting the generality of these Terms, in using the App, you specifically agree not to post or transmit any content or engage in any activity that, in our sole discretion attempts to impersonate another person or entity.</p>
                        <p>All free user accounts are associated with individuals. Login credentials should not be shared by users with others. The individual associated with the account will be held responsible for all actions taken by the account, without limitation.</p>
                        <p>Users may provide a business name or a company name, which is associated with the User’s Account. Users acknowledge and agree that where a business name or company name is associated with their Account, this User Agreement is a contract with the User as an individual (not the business or company) and Users remain solely responsible for all activity undertaken in respect of their Account.</p>
                    </div>

                    <div className="mt-4">
                        <h2>Lawful use of the App</h2>
                        <p>You acknowledge that while using the App, you shall not engage in unlawful activities, which include but are not limited to;</p>
                        <ul className="list-unstyled">
                            <li><p>1. Any form of fraud;</p></li>
                            <li><p>2. Abuse of information accessed through or by use of the App;</p></li>
                            <li><p>3. Sexual and any other form of harassment;</p></li>
                            <li><p>4. Any other act or omission prohibited by law;</p></li>
                        </ul>
                        <p>A user who does not comply with clause 4.1. above agrees to fully indemnify Ebusaka of all liabilities, losses, costs, damages and expenses (including, without limitation, reasonable attorney’s fees, charges and disbursements) incurred as a result of the breach or non-compliance.</p>
                    </div>

                    <div className="mt-4">
                        <h2>Warranty of Correctness of Information</h2>
                        <p>By providing information required for account opening with Ebusaka, which includes but is not limited to; your email, Telephone number, National Identification Number, Passport Number etc., you warrant to Ebusaka that the information is true, correct, accurate and free of error.</p>
                        <p>You acknowledge that you shall fully indemnify Ebusaka of all liabilities, losses, costs, damages and expenses (including, without limitation, reasonable attorney’s fees, charges and disbursements) incurred as a result of the inaccuracy of information provided by you.</p>
                    </div>

                    <div className="mt-4">
                        <h2>Nature of Relationship</h2>
                        <p>Nothing in these Terms shall in any way be construed as forming a joint venture, partnership or an employer-employee relationship between Ebusaka and any User or between two or more Users.</p>
                        <p>You acknowledge and agree that the relationship between the User and the Service Provider is that of an independent contractor. Nothing in these Terms creates a partnership, joint venture, agency or employment relationship between Users.</p>
                        <p>The Service Provider acknowledges and agrees that the relationship between Ebusaka and the Service Provider is that of an independent contractor.</p>
                    </div>

                    <div className="mt-4">
                        <h2>Use of services</h2>
                        <p><strong>1. User Account</strong></p>
                        <ul className="list-unstyled">
                            <li><p>1.1 In order to use most aspects of the services, you must register for and maintain an active personal User Account</p></li>
                            <li><p>1.2 Account registration requires you to submit to Ebusaka certain personal information, such as your name, address, mobile phone number and age.</p></li>
                            <li><p>1.3 You agree to maintain accurate, complete, and up-to-date information in your Account. Your failure to maintain accurate, complete, and up-to-date Account information, including having an invalid or expired payment method on file, may result in your inability to access and use the Services or Ebusaka’s termination of these Terms with you.</p></li>
                            <li><p>1.4 You are responsible for all activity that occurs under your Account, and you agree to maintain the security and secrecy of your Account username and password at all times.</p></li>
                        </ul>

                        <p><strong>2. Text messaging</strong><br/>By creating an Account, you agree that Ebusaka may send you text (SMS) messages as part of the normal business operation of your use of the App.</p>
                        <ul className="list-unstyled">
                            <li><p>2.1 By creating an Account, you agree that Ebusaka may send you text (SMS) messages as part of the normal business operation of your use of the App.</p></li>
                            <li><p>2.2 You may opt-out of receiving text (SMS) messages from Ebusaka at any time by contacting us on info@ebusaka.com. You acknowledge that opting out of receiving text (SMS) messages may impact your use of the App.</p></li>
                        </ul>

                        <p><strong>3. Network access and devices</strong></p>
                        <ul className="list-unstyled">
                            <li><p>3.1 You are responsible for obtaining the data network access necessary to use the App. Your mobile network’s data and messaging rates and fees may apply if you access or use the App from a wireless-enabled device and you shall be responsible for such rates and fees.</p></li>
                            <li><p>3.2 You are responsible for acquiring and updating compatible hardware or devices necessary to access and use the App and any updates thereto.</p></li>
                            <li><p>3.3. Ebusaka does not guarantee that the App, or any portion thereof, will function on any particular hardware or devices. In addition, the App may be subject to malfunctions and delays inherent in the use of the Internet and electronic communications.</p></li>
                        </ul>
                    </div>

                    <div className="mt-4">
                        <h2>Payments</h2>
                        <p>Use of the App may result in charges to you for the services you receive from the Service Provider.</p>
                        <p>Ebusaka may charge a commission on fees paid by the User.</p>
                        <p>Ebusaka shall pay to the Service Provider a commission out of the fees paid by the User.</p>
                        <p>Charges paid by you are final and non-refundable, unless otherwise determined by Ebusaka. </p>
                    </div>

                    <div className="mt-4">
                        <h2>Taxes</h2>
                        <p>You are responsible for paying any taxes which may be applicable to you under the laws of Zambia.</p>
                        <p>You acknowledge that you will comply with your obligations under the Income Tax Act, Cap 323.</p>
                    </div>

                    <div className="mt-4">
                        <h2>License</h2>
                        <p>Subject to your compliance with these Terms, Ebusaka grants you a limited, non-exclusive, non-sub licensable, revocable, non-transferrable license to: </p>
                        <ul className="list-unstyled">
                            <li><p>1. Access and use the App on your personal device solely in connection with your use of the Services; and</p></li>
                            <li><p>2. Access and use any content, information and related materials that may be made available through the Services, in each case solely for your personal, non-commercial use.</p></li>
                        </ul>
                        <p>Any rights not expressly granted herein are reserved by Ebusaka.</p>
                    </div>

                    <div className="mt-4">
                        <h2>Restrictions</h2>
                        <p>You shall not:</p>
                        <ul className="list-unstyled">
                            <li><p>1. Remove any copyright, trademark or other proprietary notices from any portion of the App;</p></li>
                            <li><p>2. Reproduce, modify, prepare derivative works based upon, distribute, license, lease, sell, resell, transfer, publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit the App except as expressly permitted by Ebusaka;</p></li>
                            <li><p>3. Decompile, reverse engineer or disassemble the App except as may be permitted by applicable law;</p></li>
                            <li><p>4. Ink to, mirror or frame any portion of the App;</p></li>
                            <li><p>5. Cause or launch any programs or scripts for the purpose of scraping, indexing, surveying, or otherwise data mining any portion of the App or unduly burdening or hindering the operation and/or functionality of any aspect of the App; or</p></li>
                            <li><p>6. Attempt to gain unauthorized access to or impair any aspect of the App or its related systems or networks.</p></li>
                        </ul>
                    </div>

                    <div className="mt-4">
                        <h2>Ebusaka Reservation of Rights</h2>
                        <p>Ebusaka expressly reserves the right to immediately modify, delete content from, suspend or terminate your Account and refuse current or future use of any Ebusaka service, if Ebusaka, in its sole discretion believes you have:</p>
                        <ul className="list-unstyled">
                            <li><p>1. Violated or tried to violate the rights of others; or</p></li>
                            <li><p>2. Acted inconsistently with the spirit or letter of these Terms.</p></li>
                        </ul>
                        <p>In such event, your Account may be suspended or cancelled immediately in our discretion, all the information and content contained within it deleted permanently and you will not be entitled to any refund of any of the amounts you’ve paid for such Account. Ebusaka accepts no liability for information or content that is deleted.</p>
                    </div>

                    <div className="mt-4">
                        <h2>Third Party Rights, Terms and Policies</h2>
                        <p>The App may be made available or accessed in connection with third party services and content (including advertising) that Ebusaka does not control. You acknowledge that different terms of use and privacy policies may apply to your use of such third-party services and content.</p>
                        <p>Ebusaka does not endorse such third-party services and content and in no event shall Ebusaka be responsible or liable for any products or services of such third-party providers. Additionally, Apple Inc., Google, Inc., Microsoft Corporation or BlackBerry Limited and/or their applicable international subsidiaries and affiliates will be third-party beneficiaries to this contract if you access the Services using Applications developed for Apple iOS, Android, Microsoft Windows, or Blackberry-powered mobile devices, respectively.</p>
                        <p>These third-party beneficiaries are not parties to these Terms and are not responsible for the provision or support of the App in any manner. Your access to the App using these devices is subject to terms set forth in the applicable third-party beneficiary’s terms of service.</p>
                        <p>Ebusaka uses services provided by Google Maps. Google Maps may be used to help find addresses, and is used to display maps and calculate directions and distances to certain locations. Ebusaka does not transmit contact information to Google Maps (e.g. phone numbers, emails), but addresses entered into the App may be submitted to Google Maps services to perform location queries. Please review Google Maps’ Privacy Policy in order to understand how Google Maps uses the data it collects.</p>
                    </div>

                    <div className="mt-4">
                        <h2>Ownership</h2>
                        <p>The App and all rights therein are and shall remain Ebusaka’s property. Neither these Terms nor your use of the App convey or grant to you any rights: (i) in or related to the App except for the limited license granted above; or (ii) to use or reference in any manner Ebusaka’s company names, logos, product and service names, trademarks or services marks or those of Ebusaka’s licensors.</p>
                    </div>

                    <div className="mt-4">
                        <h2>Indemnity</h2>
                        <p>You agree to indemnify and hold harmless Ebusaka, and its subsidiaries, affiliates, officers, agents, or other partners, and employees, from any claim or demand, including reasonable attorneys’ fees, made by any third party due to or arising out of your use of and access to our App, your violation of the Terms, your violation of any rights of another person or entity, or your violation of any applicable laws or regulations.</p>
                    </div>

                    <div className="mt-4">
                        <h2>Privacy</h2>
                        <p>Registration Data and certain other information about you are subject to our Privacy Policy. For more information, see our full privacy policy <Link to="/privacy">click here</Link></p>
                    </div>

                    <div className="mt-4">
                        <h2>Disclaimer</h2>
                        <p>The App is provided “as is” and “as available.” Ebusaka disclaims all representations and warranties, express, implied or statutory, not expressly set out in these terms, including the implied warranties of merchantability, fitness for a particular purpose and non-infringement. In addition, Ebusaka makes no representation, warranty, or guarantee regarding the reliability, timeliness, quality, suitability or availability of the services or any services or goods requested through the use of the services, or that the services will be uninterrupted or error-free. </p>
                    </div>

                    <div className="mt-4">
                        <h2>Limitation of Liability</h2>
                        <p>Ebusaka shall not be liable for indirect, incidental, special, exemplary, punitive or consequential damages, including lost profits, lost data, personal injury or property damage related to, in connection with, or otherwise resulting from any use of the services offered through the App.</p>
                        <p>Ebusaka shall not be liable for any damages, liability or losses arising out of:</p>
                        <ul className="list-unstyled">
                            <li><p>1. Your use of or reliance on the services or your inability to access or use the services; or any transaction or relationship between you and any Service Provider.</p></li>
                            <li><p>2. Your Waste shall not be liable for delay or failure in performance resulting from causes beyond Ebusaka’s reasonable control.</p></li>
                        </ul>
                        <p>Ebusaka’s services may be used by you to request and schedule waste collection services with Service Providers, but you agree that Ebusaka has no responsibility or liability to you related to any waste collection services provided to you by third party providers other than as expressly set forth in these terms.</p>
                    </div>

                    <div className="mt-4">
                        <h2>Force Majeure</h2>
                        <p>If a Force Majeure Event gives rise to a failure or delay in either party performing any obligation under these Terms, that obligation will be suspended for the duration of the Force Majeure Event.</p>
                        <p>A party that becomes aware of a Force Majeure Event which gives rise to, or which is likely to give rise to, any failure or delay in that party performing any obligation under these Terms, must: promptly notify the other; and inform the other of the period for which it is estimated that such failure or delay will continue.</p>
                        <p>A party whose performance of its obligations under the Terms is affected by a Force Majeure Event must take reasonable steps to mitigate the effects of the Force Majeure Event.</p>
                        <p>“Force Majeure Event” means an event, or a series of related events, that is outside the reasonable control of the party affected including but not limited to; failures of the internet or any public telecommunications network, hacker attacks, denial of service attacks, virus or other malicious software attacks or infections, power failures, industrial disputes affecting any third party, changes to the law, disasters, explosions, fires, floods, riots, terrorist attacks, epidemics, pandemics and wars.</p>
                    </div>

                    <div className="mt-4">
                        <h2>Dispute Resolution</h2>
                        <p>A claim or dispute arising under or relating to these terms shall be settled by binding arbitration.</p>
                        <p>In the event of a claim or dispute arising under or relating to these terms, either party may request that the claim or dispute be resolved by arbitration in accordance with the Chartered Institute of Arbitrators (CIArb)- Zambia Rules. The arbitration shall be presided over by a single arbitrator appointed by the CIArb unless the Parties request appointment of the same person as arbitrator. The seat of arbitration shall be Lusaka, Zambia.</p>
                        <p>The language of arbitration shall be English.</p>
                        <p>The arbitrator’s decision shall be final and binding on the Parties.</p>
                        <p>Each Party shall bear its own costs unless otherwise agreed in writing by the Parties.</p>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    );
};

export default TermOfUse;
