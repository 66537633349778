import React from 'react';
import Header from "../../components/layout/Header";
import Footer from "../../components/layout/Footer";
import SideHeader from "../../components/layout/SideHeader";
import Accordion from "../../components/layout/Accordion";
import faq from "../../json/faq.json";
import AppDownload from "../../components/layout/AppDownload";

const TradeWaste = () => {
    return (
        <>
            <Header/>
            <SideHeader
                title="Trade Waste"
                subtitle="Turn Waste into Opportunities"
                description="Welcome to Ebusaka's Trade Waste service – the platform that empowers residents to transform waste into valuable resources. We believe in creating a circular economy where waste becomes a valuable raw material, offering income and livelihood opportunities for individuals and communities."
                img_src={"/assets/resources/trade-waste.jpeg"}
            />

            <section id="trade-waste">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-7">
                            <h1>How It Works</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card-block text-center" style={{height: 358}}>
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M35.9805 15.854C35.9805 16.9979 36.4349 18.0949 37.2437 18.9038C38.0526 19.7126 39.1496 20.167 40.2935 20.167C41.4373 20.167 42.5344 19.7126 43.3432 18.9038C44.1521 18.0949 44.6065 16.9979 44.6065 15.854C44.6065 14.7101 44.1521 13.6131 43.3432 12.8043C42.5344 11.9954 41.4373 11.541 40.2935 11.541C39.1496 11.541 38.0526 11.9954 37.2437 12.8043C36.4349 13.6131 35.9805 14.7101 35.9805 15.854Z" stroke="#2D7A31" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M40.293 22.084C38.768 22.084 37.3054 22.6898 36.2271 23.7681C35.1488 24.8465 34.543 26.309 34.543 27.834V32.626H37.417L38.375 41.252H42.209L43.167 32.626H46.043V27.834C46.043 26.309 45.4372 24.8465 44.3588 23.7681C43.2805 22.6898 41.818 22.084 40.293 22.084Z" stroke="#2D7A31" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M3.39453 15.854C3.39453 16.9979 3.84894 18.0949 4.65778 18.9038C5.46662 19.7126 6.56365 20.167 7.70753 20.167C8.85141 20.167 9.94844 19.7126 10.7573 18.9038C11.5661 18.0949 12.0205 16.9979 12.0205 15.854C12.0205 14.7101 11.5661 13.6131 10.7573 12.8043C9.94844 11.9954 8.85141 11.541 7.70753 11.541C6.56365 11.541 5.46662 11.9954 4.65778 12.8043C3.84894 13.6131 3.39453 14.7101 3.39453 15.854Z" stroke="#2D7A31" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M7.70703 22.084C6.18204 22.084 4.7195 22.6898 3.64117 23.7681C2.56283 24.8465 1.95703 26.309 1.95703 27.834V32.626H4.83303L5.79103 41.252H9.62503L10.583 32.626H13.459V27.834C13.459 26.309 12.8532 24.8465 11.7749 23.7681C10.6966 22.6898 9.23203 22.084 7.70703 22.084Z" stroke="#2D7A31" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M23.043 1.95801L26.877 5.79201L23.043 9.62601" stroke="#2D7A31" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M18.25 5.79199H26.876" stroke="#2D7A31" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M23.041 38.376L19.207 42.21L23.041 46.044" stroke="#2D7A31" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M27.833 42.208H19.207" stroke="#2D7A31" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M19.7437 27.412C19.7664 28.0585 19.9789 28.6839 20.3547 29.2105C20.7304 29.737 21.2527 30.1414 21.8567 30.3732C22.4606 30.6049 23.1193 30.6539 23.7509 30.5139C24.3824 30.3739 24.9588 30.0512 25.4082 29.586C25.8576 29.1207 26.1602 28.5335 26.2783 27.8976C26.3963 27.2616 26.3246 26.6049 26.0721 26.0093C25.8196 25.4138 25.3974 24.9057 24.8582 24.5484C24.319 24.1911 23.6865 24.0004 23.0397 24C22.3595 24.0231 21.688 23.8423 21.1114 23.4809C20.5348 23.1194 20.0795 22.5938 19.8039 21.9715C19.5284 21.3492 19.4452 20.6588 19.5651 19.9889C19.685 19.319 20.0024 18.7003 20.4767 18.2122C20.9509 17.7241 21.5603 17.389 22.2265 17.25C22.8927 17.1109 23.5852 17.1742 24.2151 17.4318C24.845 17.6894 25.3835 18.1294 25.7614 18.6954C26.1393 19.2614 26.3393 19.9275 26.3357 20.608" stroke="#2D7A31" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M23.0391 14.416V17.196" stroke="#2D7A31" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M23.0391 30.824V33.584" stroke="#2D7A31" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <h2>Trade and Earn</h2>
                                <p>Residents can take their recyclables to nearby drop-off recycling points, where we weigh and collect the materials. In return, they receive points in their Ebusaka account. These points can be used as actual cash or redeemed for discount coupons with our partner businesses</p>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="card-block text-center" style={{height: 358}}>
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.66697 23.0419C8.71307 19.0057 10.3488 15.1504 13.2193 12.3125C16.0898 9.47465 19.9635 7.88306 24 7.88306C28.0365 7.88306 31.9102 9.47465 34.7807 12.3125C37.6511 15.1504 39.2869 19.0057 39.333 23.0419V35.4999C39.333 36.2627 39.636 36.9942 40.1753 37.5336C40.7147 38.0729 41.4462 38.3759 42.209 38.3759H5.79297C6.55538 38.3754 7.28639 38.0722 7.82532 37.5329C8.36424 36.9936 8.66697 36.2624 8.66697 35.4999V23.0419Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M24 1.95996V7.70996" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M28.6967 42.208C28.4766 43.2911 27.8888 44.2648 27.033 44.9642C26.1772 45.6636 25.1059 46.0456 24.0007 46.0456C22.8954 46.0456 21.8242 45.6636 20.9684 44.9642C20.1126 44.2648 19.5248 43.2911 19.3047 42.208" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M28.793 14.3579C30.2318 15.0718 31.4428 16.1733 32.2894 17.5384C33.1361 18.9034 33.5848 20.4777 33.585 22.0839" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <h2>Recycler Notifications</h2>
                                <p>Recyclers can use the Ebusaka app to notify residents when they are in need of specific recyclable materials, such as plastic bottles. Residents receive notifications and can trade their recyclables for rewards</p>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="card-block text-center" style={{height: 358}}>
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M22.8635 3.80204C22.9576 3.56539 23.1206 3.36243 23.3313 3.21944C23.5421 3.07644 23.7909 3 24.0455 3C24.3002 3 24.549 3.07644 24.7598 3.21944C24.9705 3.36243 25.1335 3.56539 25.2275 3.80204L26.5915 7.21204H29.4775C29.7384 7.21173 29.993 7.29162 30.2069 7.44089C30.4209 7.59016 30.5837 7.80159 30.6734 8.04653C30.7632 8.29146 30.7754 8.55805 30.7085 8.81018C30.6417 9.06231 30.4989 9.28779 30.2995 9.45604L27.8635 11.514L28.7475 14.716C28.8198 14.9745 28.8086 15.2491 28.7153 15.5008C28.6221 15.7524 28.4516 15.9681 28.2284 16.117C28.0052 16.2659 27.7405 16.3404 27.4724 16.3298C27.2043 16.3192 26.9464 16.2241 26.7355 16.058L24.0415 13.948L21.3575 16.034C21.1465 16.1976 20.8894 16.2907 20.6226 16.3C20.3558 16.3093 20.0928 16.2345 19.8709 16.086C19.649 15.9376 19.4794 15.723 19.3862 15.4729C19.293 15.2227 19.2809 14.9495 19.3515 14.692L20.2315 11.53L17.7835 9.45604C17.5842 9.28779 17.4414 9.06231 17.3746 8.81018C17.3077 8.55805 17.3199 8.29146 17.4096 8.04653C17.4994 7.80159 17.6622 7.59016 17.8761 7.44089C18.0901 7.29162 18.3447 7.21173 18.6055 7.21204H21.4975L22.8635 3.80204Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M7.53151 15.302C7.6256 15.0654 7.78857 14.8624 7.9993 14.7194C8.21004 14.5764 8.45884 14.5 8.71351 14.5C8.96818 14.5 9.21699 14.5764 9.42773 14.7194C9.63846 14.8624 9.80142 15.0654 9.89551 15.302L11.2595 18.712H14.1455C14.4064 18.7117 14.661 18.7916 14.8749 18.9409C15.0888 19.0902 15.2517 19.3016 15.3414 19.5465C15.4311 19.7915 15.4434 20.0581 15.3765 20.3102C15.3096 20.5623 15.1668 20.7878 14.9675 20.956L12.5315 23.014L13.4155 26.216C13.4878 26.4745 13.4765 26.7491 13.3833 27.0008C13.2901 27.2524 13.1196 27.4681 12.8964 27.617C12.6731 27.7659 12.4085 27.8404 12.1404 27.8298C11.8722 27.8192 11.6143 27.7241 11.4035 27.558L8.70951 25.448L6.02551 27.534C5.81452 27.6976 5.55739 27.7907 5.29057 27.8C5.02375 27.8093 4.76076 27.7345 4.53885 27.586C4.31694 27.4376 4.14738 27.223 4.05417 26.9729C3.96096 26.7227 3.94883 26.4495 4.01951 26.192L4.89951 23.03L2.45151 20.956C2.25218 20.7878 2.1094 20.5623 2.04252 20.3102C1.97564 20.0581 1.98789 19.7915 2.07761 19.5465C2.16733 19.3016 2.33019 19.0902 2.54411 18.9409C2.75803 18.7916 3.01266 18.7117 3.27351 18.712H6.16551L7.53151 15.302Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M38.1952 15.302C38.2893 15.0654 38.4523 14.8624 38.663 14.7194C38.8737 14.5764 39.1225 14.5 39.3772 14.5C39.6319 14.5 39.8807 14.5764 40.0914 14.7194C40.3021 14.8624 40.4651 15.0654 40.5592 15.302L41.9232 18.712H44.8092C45.0701 18.7117 45.3247 18.7916 45.5386 18.9409C45.7525 19.0902 45.9154 19.3016 46.0051 19.5465C46.0948 19.7915 46.1071 20.0581 46.0402 20.3102C45.9733 20.5623 45.8305 20.7878 45.6312 20.956L43.1952 23.014L44.0792 26.216C44.1515 26.4745 44.1402 26.7491 44.047 27.0008C43.9537 27.2524 43.7833 27.4681 43.5601 27.617C43.3368 27.7659 43.0722 27.8404 42.8041 27.8298C42.5359 27.8192 42.278 27.7241 42.0672 27.558L39.3832 25.45L36.6992 27.536C36.4882 27.6996 36.2311 27.7927 35.9643 27.802C35.6974 27.8113 35.4344 27.7365 35.2125 27.588C34.9906 27.4396 34.8211 27.225 34.7279 26.9749C34.6346 26.7247 34.6225 26.4515 34.6932 26.194L35.5732 23.032L33.1312 20.964C32.9319 20.7958 32.7891 20.5703 32.7222 20.3182C32.6553 20.0661 32.6676 19.7995 32.7573 19.5545C32.847 19.3096 33.0099 19.0982 33.2238 18.9489C33.4377 18.7996 33.6924 18.7197 33.9532 18.72H36.8452L38.1952 15.302Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M32.4452 32.552C32.5393 32.3154 32.7023 32.1124 32.913 31.9694C33.1237 31.8264 33.3725 31.75 33.6272 31.75C33.8819 31.75 34.1307 31.8264 34.3414 31.9694C34.5521 32.1124 34.7151 32.3154 34.8092 32.552L36.1732 35.962H39.0592C39.3201 35.9617 39.5747 36.0416 39.7886 36.1909C40.0025 36.3402 40.1654 36.5516 40.2551 36.7965C40.3448 37.0415 40.3571 37.3081 40.2902 37.5602C40.2233 37.8123 40.0805 38.0378 39.8812 38.206L37.4452 40.264L38.3292 43.466C38.4015 43.7245 38.3902 43.9991 38.297 44.2508C38.2037 44.5024 38.0333 44.7181 37.8101 44.867C37.5868 45.0159 37.3222 45.0904 37.0541 45.0798C36.7859 45.0692 36.528 44.9741 36.3172 44.808L33.6332 42.7L30.9492 44.786C30.7382 44.9496 30.4811 45.0427 30.2143 45.052C29.9474 45.0613 29.6844 44.9865 29.4625 44.838C29.2406 44.6896 29.0711 44.475 28.9779 44.2249C28.8846 43.9747 28.8725 43.7015 28.9432 43.444L29.8232 40.282L27.3812 38.214C27.1819 38.0458 27.0391 37.8203 26.9722 37.5682C26.9053 37.3161 26.9176 37.0495 27.0073 36.8045C27.097 36.5596 27.2599 36.3482 27.4738 36.1989C27.6877 36.0496 27.9424 35.9697 28.2032 35.97H31.0952L32.4452 32.552Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M13.2815 32.552C13.3756 32.3154 13.5386 32.1124 13.7493 31.9694C13.96 31.8264 14.2088 31.75 14.4635 31.75C14.7182 31.75 14.967 31.8264 15.1777 31.9694C15.3885 32.1124 15.5514 32.3154 15.6455 32.552L17.0095 35.962H19.8955C20.1564 35.9617 20.411 36.0416 20.6249 36.1909C20.8388 36.3402 21.0017 36.5516 21.0914 36.7965C21.1811 37.0415 21.1934 37.3081 21.1265 37.5602C21.0596 37.8123 20.9168 38.0378 20.7175 38.206L18.2815 40.264L19.1655 43.466C19.2378 43.7245 19.2265 43.9991 19.1333 44.2508C19.0401 44.5024 18.8696 44.7181 18.6464 44.867C18.4231 45.0159 18.1585 45.0904 17.8904 45.0798C17.6222 45.0692 17.3643 44.9741 17.1535 44.808L14.4595 42.698L11.7755 44.784C11.5645 44.9476 11.3074 45.0407 11.0406 45.05C10.7737 45.0593 10.5108 44.9845 10.2889 44.836C10.0669 44.6876 9.89738 44.473 9.80417 44.2229C9.71096 43.9727 9.69883 43.6995 9.76951 43.442L10.6495 40.28L8.20151 38.206C8.00219 38.0378 7.8594 37.8123 7.79252 37.5602C7.72564 37.3081 7.73789 37.0415 7.82761 36.7965C7.91733 36.5516 8.08019 36.3402 8.29411 36.1909C8.50803 36.0416 8.76266 35.9617 9.02351 35.962H11.9155L13.2815 32.552Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <h2>Rating & Feedback System</h2>
                                <p>Your satisfaction is our priority. Rate your garbage collectors after each pickup and provide feedback on your experience. Your valuable input helps us continuously improve our service delivery.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="gl-content" style={{background: "white", paddingBottom: 30}}>
                <div className="container">
                    <div className="row" style={{marginBottom: 68}}>
                        <div className="col-md-6">
                            <div id="gl-content-content-img">
                                <img src="/assets/resources/community-team.jpg" className="img-fluid" alt="community people" />
                            </div>
                        </div>

                        <div className="col-md-6 my-auto">
                            <h1>Changing Mindsets, Creating Opportunities</h1>
                            <p>At Ebusaka, we aim to change the way people view waste – from something discarded to a valuable resource. By participating in Trade Waste, residents play an active role in promoting sustainability and environmental preservation.</p>
                        </div>
                    </div>

                    <div className="row" style={{marginBottom: 68}}>
                        <div className="col-12">
                            <h1>Join the Movement</h1>
                            <p>Be a part of the waste-to-resource revolution. Embrace the opportunity to turn your waste into income and livelihood opportunities. Together, let's build a cleaner, greener, and more prosperous future for our communities and the planet.</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <h1>Empowerment through Waste</h1>
                            <p>Trade Waste is not just about recycling; it's about empowering individuals and communities to take control of their waste. By viewing waste as a valuable raw material, we enable residents to take charge of their environmental impact and contribute to a sustainable future.</p>
                        </div>
                    </div>
                </div>
            </section>

            <Accordion
                title="Frequently Asked Questions (FAQs)"
                data={faq.support}
            />

            <AppDownload
                img_src="/assets/resources/app-download.png"
                title="Start Trading Today"
                description={
                    [
                        "Download the Ebusaka app and start trading your waste for rewards. Join the movement towards responsible waste management and unlock the potential for a greener and more prosperous Zambia.",
                        "Together, let's transform waste into opportunities for a brighter future. Ebusaka Trade Waste – Turning Waste into Income, Livelihoods, and a Cleaner Environment."
                    ]
                }
            />

            <Footer/>
        </>
    );
};

export default TradeWaste;
