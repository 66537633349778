import React, {useState} from 'react';
import Header from "../../components/layout/Header";
import Footer from "../../components/layout/Footer";
import $ from "jquery";
import {getPreSignedUrl, S3Upload, applyForm} from "../../api/app";
const Buffer = require('buffer/').Buffer;

const Career = () => {


    const [uploadPercentage, setUploadPercentage] = useState("Please Wait");
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);

    const showModalCareer = () => {
        $("#modalCareer").modal({ backdrop: 'static', focus: false, show: true });
    }

    const handleOnSubmit = async(e) => {
        e.preventDefault();

        setLoading(true);

        let file_meta = $("#inputFile")[0].files[0];

        let data = {
            name: `${$("#inputFirstName").val()+" "+$("#inputLastName").val()}`,
            email: $("#inputEmail").val(),
            mobile: $("#inputMobile").val(),
            location: $("#inputAddress").val(),
            specialist: $("#inputSpecialist").val(),
            profile: $("#inputSocial").val(),
            docs: [await submitFileUpload(file, file_meta.type)]
        }

        applyForm("post", data).then((result) => {
            setLoading(false);
            if(!result.error) {
                resetForm("modalCareer", "apply_form");
                $("#modalMessage").modal({ show: true });
            }
        })

        return false;
    };

    const resetForm = (id, form) => {
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        $(".modal-backdrop").remove();
    };

    const handleFile = (event) => {
        if(event.target.files[0] !== undefined) {
            let reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = () => {
                setFile(reader.result);
            };
            reader.onerror = () => {
                setFile(null);
            };
        }
    }

    const submitFileUpload = (data, type) => {
        return getPreSignedUrl().then(async (response) => {
            if (response.error) {
                return false;
            }

            const config = {
                headers: {
                    'Content-Type': `${type}; charset=utf-8`,
                    'x-amz-acl': 'public-read'
                },
                onUploadProgress: progressEvent => {
                    let progressPercentage = Math.round(progressEvent.loaded / progressEvent.total * 100);
                    setUploadPercentage(`Uploading Documents - ${progressPercentage}%`);
                }
            };

            const buffer = new Buffer.from(data.split(",")[1], 'base64')

            return S3Upload(response.payload.url, buffer, config).then((result) => {
                if (!result.error) {
                    return result.payload.config.url.replace(/\?.*$/g,"");
                }

                return null;
            });
        })
    };

    return (
        <>
            <Header/>
            <section id="career">
                <div className="container">
                    <div id="card-career">
                        <h1>Join our Talent Community</h1>
                        <p>At Ebusaka, we believe that our success lies in the talent and dedication of our team members. We are constantly seeking exceptional individuals who are passionate about making a positive impact on the environment and community.</p>
                        <div className="card-career-img-contain">
                            <img src="/assets/resources/career.jpg" className="img-fluid" alt="career" />
                        </div>
                    </div>

                    <h2>Why Join Ebusaka</h2>
                    <div className="row">
                        <div className="col-md-9">
                            <p>Working with Ebusaka means being part of a team that is at the forefront of revolutionizing waste management in Africa. As an innovative green tech startup, we offer a dynamic and inclusive work environment that fosters creativity, collaboration, and growth. Here are some reasons to consider joining our team:</p>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-md-3">
                            <svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M24.7874 23.61C24.7731 24.2378 24.8843 24.8621 25.1146 25.4463C25.345 26.0304 25.6897 26.5627 26.1286 27.0117C26.5675 27.4608 27.0918 27.8176 27.6705 28.0612C28.2493 28.3048 28.8709 28.4303 29.4988 28.4303C30.1267 28.4303 30.7483 28.3048 31.3271 28.0612C31.9058 27.8176 32.43 27.4608 32.869 27.0117C33.3079 26.5627 33.6526 26.0304 33.8829 25.4463C34.1133 24.8621 34.2245 24.2378 34.2102 23.61C34.2245 22.9823 34.1133 22.358 33.8829 21.7738C33.6526 21.1897 33.3079 20.6574 32.869 20.2084C32.43 19.7593 31.9058 19.4025 31.3271 19.1589C30.7483 18.9153 30.1267 18.7898 29.4988 18.7898C28.8709 18.7898 28.2493 18.9153 27.6705 19.1589C27.0918 19.4025 26.5675 19.7593 26.1286 20.2084C25.6897 20.6574 25.345 21.1897 25.1146 21.7738C24.8843 22.358 24.7731 22.9823 24.7874 23.61Z" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M38.9228 37.7456C38.3855 35.6544 37.168 33.8013 35.4618 32.4781C33.7557 31.1549 31.6579 30.4368 29.4988 30.4368C27.3397 30.4368 25.2419 31.1549 23.5357 32.4781C21.8296 33.8013 20.612 35.6544 20.0748 37.7456H38.9228Z" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M25.9674 5.94202C25.9549 6.41393 26.0371 6.88356 26.2091 7.32319C26.3811 7.76283 26.6394 8.16355 26.9688 8.50174C27.2982 8.83992 27.692 9.10871 28.1269 9.29223C28.5618 9.47576 29.0291 9.57032 29.5012 9.57032C29.9733 9.57032 30.4406 9.47576 30.8755 9.29223C31.3105 9.10871 31.7043 8.83992 32.0336 8.50174C32.363 8.16355 32.6213 7.76283 32.7933 7.32319C32.9653 6.88356 33.0475 6.41393 33.0351 5.94202C33.0475 5.4701 32.9653 5.00047 32.7933 4.56084C32.6213 4.12121 32.363 3.72048 32.0336 3.3823C31.7043 3.04412 31.3105 2.77533 30.8755 2.5918C30.4406 2.40827 29.9733 2.31372 29.5012 2.31372C29.0291 2.31372 28.5618 2.40827 28.1269 2.5918C27.692 2.77533 27.2982 3.04412 26.9688 3.3823C26.6394 3.72048 26.3811 4.12121 26.2091 4.56084C26.0371 5.00047 25.9549 5.4701 25.9674 5.94202Z" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M24.7874 14.1875C24.8155 12.9566 25.3242 11.7856 26.2047 10.925C27.0852 10.0644 28.2676 9.58252 29.4988 9.58252C30.73 9.58252 31.9124 10.0644 32.7929 10.925C33.6733 11.7856 34.1821 12.9566 34.2102 14.1875" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M48.348 29.5001C48.3356 29.972 48.4178 30.4417 48.5898 30.8813C48.7618 31.3209 49.0201 31.7217 49.3495 32.0598C49.6789 32.398 50.0726 32.6668 50.5076 32.8503C50.9425 33.0339 51.4098 33.1284 51.8819 33.1284C52.354 33.1284 52.8213 33.0339 53.2562 32.8503C53.6912 32.6668 54.0849 32.398 54.4143 32.0598C54.7437 31.7217 55.002 31.3209 55.174 30.8813C55.346 30.4417 55.4282 29.972 55.4158 29.5001C55.4282 29.0282 55.346 28.5586 55.174 28.1189C55.002 27.6793 54.7437 27.2786 54.4143 26.9404C54.0849 26.6022 53.6912 26.3334 53.2562 26.1499C52.8213 25.9664 52.354 25.8718 51.8819 25.8718C51.4098 25.8718 50.9425 25.9664 50.5076 26.1499C50.0726 26.3334 49.6789 26.6022 49.3495 26.9404C49.0201 27.2786 48.7618 27.6793 48.5898 28.1189C48.4178 28.5586 48.3356 29.0282 48.348 29.5001Z" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M47.1705 37.7456C47.1986 36.5147 47.7073 35.3437 48.5878 34.4831C49.4683 33.6225 50.6507 33.1406 51.8819 33.1406C53.1131 33.1406 54.2955 33.6225 55.176 34.4831C56.0565 35.3437 56.5652 36.5147 56.5933 37.7456" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M25.9674 48.3483C25.9549 48.8202 26.0371 49.2898 26.2091 49.7294C26.3811 50.1691 26.6394 50.5698 26.9688 50.908C27.2982 51.2462 27.692 51.515 28.1269 51.6985C28.5618 51.882 29.0291 51.9766 29.5012 51.9766C29.9733 51.9766 30.4406 51.882 30.8755 51.6985C31.3105 51.515 31.7043 51.2462 32.0336 50.908C32.363 50.5698 32.6213 50.1691 32.7933 49.7294C32.9653 49.2898 33.0475 48.8202 33.0351 48.3483C33.0475 47.8764 32.9653 47.4067 32.7933 46.9671C32.6213 46.5275 32.363 46.1267 32.0336 45.7886C31.7043 45.4504 31.3105 45.1816 30.8755 44.9981C30.4406 44.8145 29.9733 44.72 29.5012 44.72C29.0291 44.72 28.5618 44.8145 28.1269 44.9981C27.692 45.1816 27.2982 45.4504 26.9688 45.7886C26.6394 46.1267 26.3811 46.5275 26.2091 46.9671C26.0371 47.4067 25.9549 47.8764 25.9674 48.3483Z" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M24.7874 56.5938C24.8155 55.3629 25.3242 54.1918 26.2047 53.3312C27.0852 52.4706 28.2676 51.9888 29.4988 51.9888C30.73 51.9888 31.9124 52.4706 32.7929 53.3312C33.6733 54.1918 34.1821 55.3629 34.2102 56.5938" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M3.58427 29.5001C3.57182 29.972 3.65402 30.4417 3.82601 30.8813C3.99801 31.3209 4.25632 31.7217 4.5857 32.0598C4.91508 32.398 5.30886 32.6668 5.7438 32.8503C6.17874 33.0339 6.64604 33.1284 7.11812 33.1284C7.5902 33.1284 8.0575 33.0339 8.49244 32.8503C8.92739 32.6668 9.32116 32.398 9.65054 32.0598C9.97992 31.7217 10.2382 31.3209 10.4102 30.8813C10.5822 30.4417 10.6644 29.972 10.652 29.5001C10.6644 29.0282 10.5822 28.5586 10.4102 28.1189C10.2382 27.6793 9.97992 27.2786 9.65054 26.9404C9.32116 26.6022 8.92739 26.3334 8.49244 26.1499C8.0575 25.9664 7.5902 25.8718 7.11812 25.8718C6.64604 25.8718 6.17874 25.9664 5.7438 26.1499C5.30886 26.3334 4.91508 26.6022 4.5857 26.9404C4.25632 27.2786 3.99801 27.6793 3.82601 28.1189C3.65402 28.5586 3.57182 29.0282 3.58427 29.5001Z" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M2.40672 37.7456C2.43484 36.5147 2.94357 35.3437 3.82406 34.4831C4.70456 33.6225 5.88689 33.1406 7.11812 33.1406C8.34935 33.1406 9.53168 33.6225 10.4122 34.4831C11.2927 35.3437 11.8014 36.5147 11.8295 37.7456" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M38.5934 13.8796C42.4932 15.7179 45.7399 18.7014 47.9006 22.4322" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M40.8329 20.1462L38.5958 13.8799L44.8621 11.6428" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M17.2502 23.0223L10.5832 23.4452L10.1603 16.8003" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M18.2162 45.4966L19.9125 51.9522L13.4569 53.6238" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M41.8212 43.6356L48.4169 42.6228L49.4297 49.2185" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M10.652 23.4453C12.6986 19.3733 16.0028 16.0691 20.0748 14.0225" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M19.9125 51.9521C15.8474 49.8959 12.5459 46.5944 10.4897 42.5293" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M48.348 42.6228C46.2919 46.6879 42.9903 49.9894 38.9252 52.0456" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <h3>Significant Labor</h3>
                            <p>At Ebusaka, you will have the opportunity to contribute to solving some of the most pressing environmental challenges of our time. Your work will directly impact waste reduction, environmental preservation, and community empowerment.</p>
                        </div>

                        <div className="col-md-3">
                            <svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M45.9905 29.5002C45.9762 30.1279 46.0875 30.7523 46.3178 31.3364C46.5481 31.9206 46.8929 32.4528 47.3318 32.9019C47.7707 33.3509 48.2949 33.7077 48.8737 33.9513C49.4524 34.1949 50.074 34.3204 50.7019 34.3204C51.3299 34.3204 51.9515 34.1949 52.5302 33.9513C53.109 33.7077 53.6332 33.3509 54.0721 32.9019C54.511 32.4528 54.8558 31.9206 55.0861 31.3364C55.3164 30.7523 55.4277 30.1279 55.4133 29.5002C55.4277 28.8724 55.3164 28.2481 55.0861 27.664C54.8558 27.0798 54.511 26.5476 54.0721 26.0985C53.6332 25.6494 53.109 25.2926 52.5302 25.049C51.9515 24.8054 51.3299 24.6799 50.7019 24.6799C50.074 24.6799 49.4524 24.8054 48.8737 25.049C48.2949 25.2926 47.7707 25.6494 47.3318 26.0985C46.8929 26.5476 46.5481 27.0798 46.3178 27.664C46.0875 28.2481 45.9762 28.8724 45.9905 29.5002Z" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M22.4323 29.5001C22.4199 29.972 22.5021 30.4417 22.6741 30.8813C22.8461 31.3209 23.1044 31.7217 23.4338 32.0598C23.7631 32.398 24.1569 32.6668 24.5919 32.8503C25.0268 33.0339 25.4941 33.1284 25.9662 33.1284C26.4383 33.1284 26.9056 33.0339 27.3405 32.8503C27.7754 32.6668 28.1692 32.398 28.4986 32.0598C28.828 31.7217 29.0863 31.3209 29.2583 30.8813C29.4303 30.4417 29.5125 29.972 29.5 29.5001C29.5125 29.0282 29.4303 28.5586 29.2583 28.1189C29.0863 27.6793 28.828 27.2786 28.4986 26.9404C28.1692 26.6022 27.7754 26.3334 27.3405 26.1499C26.9056 25.9664 26.4383 25.8718 25.9662 25.8718C25.4941 25.8718 25.0268 25.9664 24.5919 26.1499C24.1569 26.3334 23.7631 26.6022 23.4338 26.9404C23.1044 27.2786 22.8461 27.6793 22.6741 28.1189C22.5021 28.5586 22.4199 29.0282 22.4323 29.5001Z" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M13.0095 29.5001C13.0095 30.125 13.2577 30.7244 13.6996 31.1663C14.1415 31.6082 14.7409 31.8564 15.3658 31.8564C15.9907 31.8564 16.5901 31.6082 17.032 31.1663C17.4739 30.7244 17.7221 30.125 17.7221 29.5001C17.7221 28.8752 17.4739 28.2758 17.032 27.8339C16.5901 27.3921 15.9907 27.1438 15.3658 27.1438C14.7409 27.1438 14.1415 27.3921 13.6996 27.8339C13.2577 28.2758 13.0095 28.8752 13.0095 29.5001Z" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M3.58426 29.5001C3.58426 30.125 3.83251 30.7244 4.27441 31.1663C4.7163 31.6082 5.31564 31.8564 5.94057 31.8564C6.5655 31.8564 7.16484 31.6082 7.60674 31.1663C8.04863 30.7244 8.29688 30.125 8.29688 29.5001C8.29688 28.8752 8.04863 28.2758 7.60674 27.8339C7.16484 27.3921 6.5655 27.1438 5.94057 27.1438C5.31564 27.1438 4.7163 27.3921 4.27441 27.8339C3.83251 28.2758 3.58426 28.8752 3.58426 29.5001Z" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M22.4323 41.2806C22.4199 41.7526 22.5021 42.2222 22.6741 42.6618C22.8461 43.1015 23.1044 43.5022 23.4338 43.8404C23.7631 44.1785 24.1569 44.4473 24.5919 44.6309C25.0268 44.8144 25.4941 44.9089 25.9662 44.9089C26.4383 44.9089 26.9056 44.8144 27.3405 44.6309C27.7754 44.4473 28.1692 44.1785 28.4986 43.8404C28.828 43.5022 29.0863 43.1015 29.2583 42.6618C29.4303 42.2222 29.5125 41.7526 29.5 41.2806C29.5125 40.8087 29.4303 40.3391 29.2583 39.8995C29.0863 39.4598 28.828 39.0591 28.4986 38.7209C28.1692 38.3827 27.7754 38.114 27.3405 37.9304C26.9056 37.7469 26.4383 37.6523 25.9662 37.6523C25.4941 37.6523 25.0268 37.7469 24.5919 37.9304C24.1569 38.114 23.7631 38.3827 23.4338 38.7209C23.1044 39.0591 22.8461 39.4598 22.6741 39.8995C22.5021 40.3391 22.4199 40.8087 22.4323 41.2806Z" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M13.0095 41.2804C13.0095 41.9053 13.2577 42.5047 13.6996 42.9466C14.1415 43.3884 14.7409 43.6367 15.3658 43.6367C15.9907 43.6367 16.5901 43.3884 17.032 42.9466C17.4739 42.5047 17.7221 41.9053 17.7221 41.2804C17.7221 40.6555 17.4739 40.0561 17.032 39.6142C16.5901 39.1723 15.9907 38.9241 15.3658 38.9241C14.7409 38.9241 14.1415 39.1723 13.6996 39.6142C13.2577 40.0561 13.0095 40.6555 13.0095 41.2804Z" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M22.4323 17.7199C22.4199 18.1918 22.5021 18.6614 22.6741 19.101C22.8461 19.5407 23.1044 19.9414 23.4338 20.2796C23.7631 20.6178 24.1569 20.8865 24.5919 21.0701C25.0268 21.2536 25.4941 21.3481 25.9662 21.3481C26.4383 21.3481 26.9056 21.2536 27.3405 21.0701C27.7754 20.8865 28.1692 20.6178 28.4986 20.2796C28.828 19.9414 29.0863 19.5407 29.2583 19.101C29.4303 18.6614 29.5125 18.1918 29.5 17.7199C29.5125 17.2479 29.4303 16.7783 29.2583 16.3387C29.0863 15.899 28.828 15.4983 28.4986 15.1601C28.1692 14.8219 27.7754 14.5532 27.3405 14.3696C26.9056 14.1861 26.4383 14.0916 25.9662 14.0916C25.4941 14.0916 25.0268 14.1861 24.5919 14.3696C24.1569 14.5532 23.7631 14.8219 23.4338 15.1601C23.1044 15.4983 22.8461 15.899 22.6741 16.3387C22.5021 16.7783 22.4199 17.2479 22.4323 17.7199Z" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M34.2127 29.5001C34.2002 29.972 34.2824 30.4417 34.4544 30.8813C34.6264 31.3209 34.8847 31.7217 35.2141 32.0598C35.5435 32.398 35.9372 32.6668 36.3722 32.8503C36.8071 33.0339 37.2744 33.1284 37.7465 33.1284C38.2186 33.1284 38.6859 33.0339 39.1208 32.8503C39.5558 32.6668 39.9496 32.398 40.2789 32.0598C40.6083 31.7217 40.8666 31.3209 41.0386 30.8813C41.2106 30.4417 41.2928 29.972 41.2804 29.5001C41.2928 29.0282 41.2106 28.5586 41.0386 28.1189C40.8666 27.6793 40.6083 27.2786 40.2789 26.9404C39.9496 26.6022 39.5558 26.3334 39.1208 26.1499C38.6859 25.9664 38.2186 25.8718 37.7465 25.8718C37.2744 25.8718 36.8071 25.9664 36.3722 26.1499C35.9372 26.3334 35.5435 26.6022 35.2141 26.9404C34.8847 27.2786 34.6264 27.6793 34.4544 28.1189C34.2824 28.5586 34.2002 29.0282 34.2127 29.5001Z" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M34.2127 41.2806C34.2002 41.7526 34.2824 42.2222 34.4544 42.6618C34.6264 43.1015 34.8847 43.5022 35.2141 43.8404C35.5435 44.1785 35.9372 44.4473 36.3722 44.6309C36.8071 44.8144 37.2744 44.9089 37.7465 44.9089C38.2186 44.9089 38.6859 44.8144 39.1208 44.6309C39.5558 44.4473 39.9496 44.1785 40.2789 43.8404C40.6083 43.5022 40.8666 43.1015 41.0386 42.6618C41.2106 42.2222 41.2928 41.7526 41.2804 41.2806C41.2928 40.8087 41.2106 40.3391 41.0386 39.8995C40.8666 39.4598 40.6083 39.0591 40.2789 38.7209C39.9496 38.3827 39.5558 38.114 39.1208 37.9304C38.6859 37.7469 38.2186 37.6523 37.7465 37.6523C37.2744 37.6523 36.8071 37.7469 36.3722 37.9304C35.9372 38.114 35.5435 38.3827 35.2141 38.7209C34.8847 39.0591 34.6264 39.4598 34.4544 39.8995C34.2824 40.3391 34.2002 40.8087 34.2127 41.2806Z" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M34.2127 17.7199C34.2002 18.1918 34.2824 18.6614 34.4544 19.101C34.6264 19.5407 34.8847 19.9414 35.2141 20.2796C35.5435 20.6178 35.9372 20.8865 36.3722 21.0701C36.8071 21.2536 37.2744 21.3481 37.7465 21.3481C38.2186 21.3481 38.6859 21.2536 39.1208 21.0701C39.5558 20.8865 39.9496 20.6178 40.2789 20.2796C40.6083 19.9414 40.8666 19.5407 41.0386 19.101C41.2106 18.6614 41.2928 18.1918 41.2804 17.7199C41.2928 17.2479 41.2106 16.7783 41.0386 16.3387C40.8666 15.899 40.6083 15.4983 40.2789 15.1601C39.9496 14.8219 39.5558 14.5532 39.1208 14.3696C38.6859 14.1861 38.2186 14.0916 37.7465 14.0916C37.2744 14.0916 36.8071 14.1861 36.3722 14.3696C35.9372 14.5532 35.5435 14.8219 35.2141 15.1601C34.8847 15.4983 34.6264 15.899 34.4544 16.3387C34.2824 16.7783 34.2002 17.2479 34.2127 17.7199Z" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M22.4323 5.94202C22.4199 6.41393 22.5021 6.88356 22.6741 7.32319C22.8461 7.76283 23.1044 8.16355 23.4338 8.50174C23.7631 8.83992 24.1569 9.10871 24.5919 9.29223C25.0268 9.47576 25.4941 9.57032 25.9662 9.57032C26.4383 9.57032 26.9056 9.47576 27.3405 9.29223C27.7754 9.10871 28.1692 8.83992 28.4986 8.50174C28.828 8.16355 29.0863 7.76283 29.2583 7.32319C29.4303 6.88356 29.5125 6.41393 29.5 5.94202C29.5125 5.4701 29.4303 5.00047 29.2583 4.56084C29.0863 4.12121 28.828 3.72048 28.4986 3.3823C28.1692 3.04412 27.7754 2.77533 27.3405 2.5918C26.9056 2.40827 26.4383 2.31372 25.9662 2.31372C25.4941 2.31372 25.0268 2.40827 24.5919 2.5918C24.1569 2.77533 23.7631 3.04412 23.4338 3.3823C23.1044 3.72048 22.8461 4.12121 22.6741 4.56084C22.5021 5.00047 22.4199 5.4701 22.4323 5.94202Z" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M22.4323 53.0585C22.4199 53.5304 22.5021 54 22.6741 54.4397C22.8461 54.8793 23.1044 55.28 23.4338 55.6182C23.7631 55.9564 24.1569 56.2252 24.5919 56.4087C25.0268 56.5922 25.4941 56.6868 25.9662 56.6868C26.4383 56.6868 26.9056 56.5922 27.3405 56.4087C27.7754 56.2252 28.1692 55.9564 28.4986 55.6182C28.828 55.28 29.0863 54.8793 29.2583 54.4397C29.4303 54 29.5125 53.5304 29.5 53.0585C29.5125 52.5866 29.4303 52.1169 29.2583 51.6773C29.0863 51.2377 28.828 50.8369 28.4986 50.4988C28.1692 50.1606 27.7754 49.8918 27.3405 49.7083C26.9056 49.5247 26.4383 49.4302 25.9662 49.4302C25.4941 49.4302 25.0268 49.5247 24.5919 49.7083C24.1569 49.8918 23.7631 50.1606 23.4338 50.4988C23.1044 50.8369 22.8461 51.2377 22.6741 51.6773C22.5021 52.1169 22.4199 52.5866 22.4323 53.0585Z" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M13.0095 17.7196C13.0095 18.3445 13.2577 18.9439 13.6996 19.3858C14.1415 19.8277 14.7409 20.0759 15.3658 20.0759C15.9907 20.0759 16.5901 19.8277 17.032 19.3858C17.4739 18.9439 17.7221 18.3445 17.7221 17.7196C17.7221 17.0947 17.4739 16.4953 17.032 16.0534C16.5901 15.6115 15.9907 15.3633 15.3658 15.3633C14.7409 15.3633 14.1415 15.6115 13.6996 16.0534C13.2577 16.4953 13.0095 17.0947 13.0095 17.7196Z" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <h3>Innovation</h3>
                            <p>We embrace technology and innovation to drive positive change. As part of our team, you will have the chance to work on cutting-edge projects that leverage disruptive technology to improve waste management practices.</p>
                        </div>

                        <div className="col-md-3">
                            <svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.8319 48.348C11.8319 48.0356 11.7079 47.7361 11.487 47.5153C11.2662 47.2945 10.9667 47.1704 10.6544 47.1704H5.94176C5.62946 47.1704 5.32995 47.2945 5.10912 47.5153C4.88828 47.7361 4.76422 48.0356 4.76422 48.348V56.5932H11.8319V48.348Z" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M25.9674 36.5677C25.9674 36.2554 25.8433 35.9559 25.6225 35.735C25.4016 35.5142 25.1021 35.3901 24.7898 35.3901H20.0772C19.7649 35.3901 19.4654 35.5142 19.2446 35.735C19.0237 35.9559 18.8997 36.2554 18.8997 36.5677V56.5933H25.9674V36.5677Z" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M40.1027 43.6353C40.1027 43.323 39.9787 43.0235 39.7578 42.8027C39.537 42.5818 39.2375 42.4578 38.9252 42.4578H34.2126C33.9003 42.4578 33.6008 42.5818 33.3799 42.8027C33.1591 43.0235 33.035 43.323 33.035 43.6353V56.5932H40.1027V43.6353Z" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M54.2382 24.7874C54.2382 24.4751 54.1141 24.1756 53.8933 23.9548C53.6725 23.7339 53.3729 23.6099 53.0606 23.6099H48.348C48.0357 23.6099 47.7362 23.7339 47.5154 23.9548C47.2945 24.1756 47.1705 24.4751 47.1705 24.7874V56.5933H54.2382V24.7874Z" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M2.41644 56.5933H56.603" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M10.4602 21.4562L16.7535 15.1628" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M24.1556 14.2068L30.149 17.7025" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M37.9271 17.1644L47.0057 10.0303" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M46.0003 7.11957C45.986 7.74733 46.0972 8.37163 46.3276 8.9558C46.5579 9.53996 46.9026 10.0722 47.3415 10.5213C47.7805 10.9703 48.3047 11.3271 48.8834 11.5707C49.4622 11.8143 50.0838 11.9398 50.7117 11.9398C51.3396 11.9398 51.9612 11.8143 52.54 11.5707C53.1187 11.3271 53.643 10.9703 54.0819 10.5213C54.5208 10.0722 54.8655 9.53996 55.0959 8.9558C55.3262 8.37163 55.4374 7.74733 55.4231 7.11957C55.4374 6.4918 55.3262 5.8675 55.0959 5.28334C54.8655 4.69918 54.5208 4.16694 54.0819 3.71788C53.643 3.26883 53.1187 2.91202 52.54 2.66841C51.9612 2.42481 51.3396 2.29932 50.7117 2.29932C50.0838 2.29932 49.4622 2.42481 48.8834 2.66841C48.3047 2.91202 47.7805 3.26883 47.3415 3.71788C46.9026 4.16694 46.5579 4.69918 46.3276 5.28334C46.0972 5.8675 45.986 6.4918 46.0003 7.11957Z" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M29.5098 20.0773C29.4954 20.7051 29.6067 21.3294 29.837 21.9136C30.0673 22.4977 30.4121 23.03 30.851 23.479C31.2899 23.9281 31.8141 24.2849 32.3929 24.5285C32.9716 24.7721 33.5932 24.8976 34.2212 24.8976C34.8491 24.8976 35.4707 24.7721 36.0494 24.5285C36.6282 24.2849 37.1524 23.9281 37.5913 23.479C38.0303 23.03 38.375 22.4977 38.6053 21.9136C38.8356 21.3294 38.9469 20.7051 38.9326 20.0773C38.9469 19.4496 38.8356 18.8253 38.6053 18.2411C38.375 17.6569 38.0303 17.1247 37.5913 16.6756C37.1524 16.2266 36.6282 15.8698 36.0494 15.6262C35.4707 15.3826 34.8491 15.2571 34.2212 15.2571C33.5932 15.2571 32.9716 15.3826 32.3929 15.6262C31.8141 15.8698 31.2899 16.2266 30.851 16.6756C30.4121 17.1247 30.0673 17.6569 29.837 18.2411C29.6067 18.8253 29.4954 19.4496 29.5098 20.0773Z" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M15.3743 11.8322C15.36 12.46 15.4713 13.0843 15.7016 13.6684C15.9319 14.2526 16.2767 14.7848 16.7156 15.2339C17.1545 15.683 17.6787 16.0398 18.2575 16.2834C18.8362 16.527 19.4578 16.6525 20.0857 16.6525C20.7137 16.6525 21.3353 16.527 21.914 16.2834C22.4928 16.0398 23.017 15.683 23.4559 15.2339C23.8948 14.7848 24.2396 14.2526 24.4699 13.6684C24.7002 13.0843 24.8115 12.46 24.7971 11.8322C24.8115 11.2045 24.7002 10.5801 24.4699 9.99599C24.2396 9.41183 23.8948 8.87958 23.4559 8.43053C23.017 7.98148 22.4928 7.62466 21.914 7.38106C21.3353 7.13745 20.7137 7.01196 20.0857 7.01196C19.4578 7.01196 18.8362 7.13745 18.2575 7.38106C17.6787 7.62466 17.1545 7.98148 16.7156 8.43053C16.2767 8.87958 15.9319 9.41183 15.7016 9.99599C15.4713 10.5801 15.36 11.2045 15.3743 11.8322Z" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M2.41651 24.7875C2.40217 25.4153 2.51343 26.0396 2.74376 26.6238C2.97408 27.2079 3.31883 27.7402 3.75774 28.1892C4.19666 28.6383 4.72088 28.9951 5.29963 29.2387C5.87838 29.4823 6.49998 29.6078 7.12791 29.6078C7.75584 29.6078 8.37743 29.4823 8.95618 29.2387C9.53493 28.9951 10.0592 28.6383 10.4981 28.1892C10.937 27.7402 11.2817 27.2079 11.5121 26.6238C11.7424 26.0396 11.8536 25.4153 11.8393 24.7875C11.8536 24.1598 11.7424 23.5355 11.5121 22.9513C11.2817 22.3671 10.937 21.8349 10.4981 21.3859C10.0592 20.9368 9.53493 20.58 8.95618 20.3364C8.37743 20.0928 7.75584 19.9673 7.12791 19.9673C6.49998 19.9673 5.87838 20.0928 5.29963 20.3364C4.72088 20.58 4.19666 20.9368 3.75774 21.3859C3.31883 21.8349 2.97408 22.3671 2.74376 22.9513C2.51343 23.5355 2.40217 24.1598 2.41651 24.7875Z" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <h3>Professional Growth</h3>
                            <p>We believe in investing in our team members' growth and development. You will have access to training and mentorship programs that will help you advance your career and reach your full potential.</p>
                        </div>

                        <div className="col-md-3">
                            <svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M44.8131 55.4157H14.1872L10.2047 39.4906C10.1197 39.1412 10.1158 38.777 10.1934 38.4259C10.2709 38.0747 10.4279 37.746 10.6521 37.4649C10.8717 37.1872 11.151 36.9624 11.4693 36.8075C11.7877 36.6526 12.1369 36.5714 12.491 36.5701H46.5094C46.8672 36.5681 47.2207 36.6476 47.5432 36.8027C47.8656 36.9577 48.1485 37.1842 48.3703 37.4649C48.5904 37.7481 48.7429 38.0778 48.8162 38.4288C48.8894 38.7799 48.8816 39.1431 48.7932 39.4906L44.8131 55.4157Z" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M3.58423 55.4158H55.4133" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M29.5 29.5V33.0351" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M22.4323 7.66016C23.707 8.97752 25.2335 10.0254 26.9209 10.7416C28.6084 11.4578 30.4226 11.8278 32.2558 11.8295C34.1225 11.8042 35.9651 11.4037 37.6739 10.6519" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M21.2548 11.8319C21.2548 12.9147 21.468 13.9869 21.8824 14.9872C22.2968 15.9876 22.9041 16.8965 23.6697 17.6622C24.4354 18.4278 25.3443 19.0352 26.3447 19.4495C27.345 19.8639 28.4172 20.0772 29.5 20.0772C30.5828 20.0772 31.655 19.8639 32.6553 19.4495C33.6557 19.0352 34.5646 18.4278 35.3303 17.6622C36.0959 16.8965 36.7033 15.9876 37.1176 14.9872C37.532 13.9869 37.7453 12.9147 37.7453 11.8319C37.7453 10.7491 37.532 9.67696 37.1176 8.6766C36.7033 7.67624 36.0959 6.76729 35.3303 6.00165C34.5646 5.23601 33.6557 4.62866 32.6553 4.2143C31.655 3.79994 30.5828 3.58667 29.5 3.58667C28.4172 3.58667 27.345 3.79994 26.3447 4.2143C25.3443 4.62866 24.4354 5.23601 23.6697 6.00165C22.9041 6.76729 22.2968 7.67624 21.8824 8.6766C21.468 9.67696 21.2548 10.7491 21.2548 11.8319Z" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M26.5549 45.4028C26.5549 46.1839 26.8652 46.933 27.4175 47.4853C27.9698 48.0376 28.7189 48.3479 29.5 48.3479C30.2811 48.3479 31.0302 48.0376 31.5825 47.4853C32.1348 46.933 32.4451 46.1839 32.4451 45.4028C32.4451 44.6218 32.1348 43.8727 31.5825 43.3204C31.0302 42.768 30.2811 42.4578 29.5 42.4578C28.7189 42.4578 27.9698 42.768 27.4175 43.3204C26.8652 43.8727 26.5549 44.6218 26.5549 45.4028Z" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M40.6904 31.8553C39.9152 29.4991 38.4162 27.4478 36.4068 25.9935C34.3975 24.5393 31.9804 23.7563 29.5 23.7563C27.0196 23.7563 24.6026 24.5393 22.5932 25.9935C20.5839 27.4478 19.0848 29.4991 18.3097 31.8553" stroke="#024731" stroke-width="2.45833" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <h3>Diverse & Inclusive Culture</h3>
                            <p>At Ebusaka, diversity is celebrated, and we foster an inclusive work environment where every team member's unique perspective is valued.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section id="apply">
                <div className="container">
                    <h1>How to Apply</h1>
                    <div className="row">
                        <div className="col-12 col-md-9">
                            <p>To apply for a position at Ebusaka, please visit our online career application system, where you can browse current vacancies and submit your application. Our application process is simple and efficient, ensuring that your skills and qualifications are considered with care.</p>
                        </div>

                        <div className="col-md-3">
                            <button className="btn btn-primary" onClick={showModalCareer.bind()}>Apply Now</button>
                        </div>
                    </div>
                </div>
            </section>

            <section id="next-step">
                <div className="container">
                    <h1>Take the Next Step</h1>
                    <div className="row">
                        <div className="col-12">
                            <p>To apply for a position at Ebusaka, please visit our online career application system, where you can browse current vacancies and submit your application. Our application process is simple and efficient, ensuring that your skills and qualifications are considered with care.</p>
                        </div>
                    </div>
                </div>
            </section>

            <div className="modal" id="modalCareer" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1>Apply</h1>
                            <div role="button" onClick={resetForm.bind(null, "modalCareer", "apply_form")} className="close pos-absolute t-15 r-15">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967L12 10.9393L17.4697 5.46967C17.7626 5.17678 18.2374 5.17678 18.5303 5.46967C18.8232 5.76256 18.8232 6.23744 18.5303 6.53033L13.0607 12L18.5303 17.4697C18.8232 17.7626 18.8232 18.2374 18.5303 18.5303C18.2374 18.8232 17.7626 18.8232 17.4697 18.5303L12 13.0607L6.53033 18.5303C6.23744 18.8232 5.76256 18.8232 5.46967 18.5303C5.17678 18.2374 5.17678 17.7626 5.46967 17.4697L10.9393 12L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967Z" fill="#22282F"/>
                                </svg>
                            </div>
                        </div>
                        <div className="modal-body">
                            <p>Please fill the required form below and ensure that you have attached your CV to the application form.</p>
                            <form id="apply_form" onSubmit={handleOnSubmit.bind()}>
                                <div className="form-row mb-4">
                                    <div className="col-6">
                                        <label htmlFor="inputFirstName">First Name</label>
                                        <input type="text" className="form-control" id="inputFirstName" placeholder="Enter your first name" required/>
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="inputLastName">Last Name</label>
                                        <input type="text" className="form-control" id="inputLastName" placeholder="Enter your last name" required/>
                                    </div>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="inputEmail">Email</label>
                                    <input type="email" className="form-control" id="inputEmail" placeholder="Enter your working email address" required/>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="inputMobile">Mobile Number</label>
                                    <input type="tel" className="form-control" id="inputMobile" placeholder="Enter your mobile number" required/>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="inputAddress">Physical Address</label>
                                    <input type="text" className="form-control" id="inputAddress" placeholder="Enter your physical address" required/>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="inputSpecialist">Specialist</label>
                                    <select className="custom-select mr-sm-2" id="inputSpecialist" required>
                                        <option selected>Select</option>
                                        <option value="data-engineer">Data Developer</option>
                                        <option value="fullstack-developer">FullStack Developer</option>
                                        <option value="creative-director">Creative Director</option>
                                        <option value="sale-marketing">Sale Marketing</option>
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="inputFile">Upload CV - You can upload multiple files</label>
                                    <input type="file" className="form-control" id="inputFile" accept="application/pdf,application" placeholder="Upload CV" onChange={handleFile.bind()} required/>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="inputSocial">Linkedin Link(Optional)</label>
                                    <input type="url" className="form-control" id="inputSocial" placeholder="Enter your linkedin profile link"/>
                                </div>

                                <div className="text-center">
                                    {loading ? <button className="btn btn-secondary" disabled>{uploadPercentage}</button> : <button className="btn btn-primary">Submit</button>}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal" id="modalMessage" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                            <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967L12 10.9393L17.4697 5.46967C17.7626 5.17678 18.2374 5.17678 18.5303 5.46967C18.8232 5.76256 18.8232 6.23744 18.5303 6.53033L13.0607 12L18.5303 17.4697C18.8232 17.7626 18.8232 18.2374 18.5303 18.5303C18.2374 18.8232 17.7626 18.8232 17.4697 18.5303L12 13.0607L6.53033 18.5303C6.23744 18.8232 5.76256 18.8232 5.46967 18.5303C5.17678 18.2374 5.17678 17.7626 5.46967 17.4697L10.9393 12L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967Z" fill="#22282F"/>
                                </svg>
                            </a>
                        </div>
                        <div className="modal-body">
                            <div className="text-center">
                                <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.625 50C15.625 31.0152 31.0152 15.625 50 15.625C68.9848 15.625 84.375 31.0152 84.375 50C84.375 68.9848 68.9848 84.375 50 84.375C31.0152 84.375 15.625 68.9848 15.625 50ZM50 9.375C27.5634 9.375 9.375 27.5634 9.375 50C9.375 72.4366 27.5634 90.625 50 90.625C72.4366 90.625 90.625 72.4366 90.625 50C90.625 27.5634 72.4366 9.375 50 9.375ZM68.8764 39.7097C70.0968 38.4893 70.0968 36.5107 68.8764 35.2903C67.656 34.0699 65.6773 34.0699 64.457 35.2903L45.8333 53.9139L39.7097 47.7903C38.4893 46.5699 36.5107 46.5699 35.2903 47.7903C34.0699 49.0107 34.0699 50.9893 35.2903 52.2097L43.6236 60.543C44.2097 61.1291 45.0045 61.4583 45.8333 61.4583C46.6621 61.4583 47.457 61.1291 48.043 60.543L68.8764 39.7097Z" fill="#359636"/>
                                </svg>
                                <h2>Thank you for your submission</h2>
                                <p>Our hiring team will carefully review your application and CV to assess your qualifications and fit for the role. If your profile matches our requirements, we will be in touch with you for the next steps of the selection process.</p>
                            </div>
                            <div className="mt-4">
                                <a href="#" className="d-flex justify-content-center" role="button" data-dismiss="modal" aria-label="Close">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967L12 10.9393L17.4697 5.46967C17.7626 5.17678 18.2374 5.17678 18.5303 5.46967C18.8232 5.76256 18.8232 6.23744 18.5303 6.53033L13.0607 12L18.5303 17.4697C18.8232 17.7626 18.8232 18.2374 18.5303 18.5303C18.2374 18.8232 17.7626 18.8232 17.4697 18.5303L12 13.0607L6.53033 18.5303C6.23744 18.8232 5.76256 18.8232 5.46967 18.5303C5.17678 18.2374 5.17678 17.7626 5.46967 17.4697L10.9393 12L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967Z" fill="#22282F"/>
                                    </svg> Close
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    );
};

export default Career;
