import React from 'react';
import PropTypes from "prop-types";
import SEO from "./Seo";

const CoverHeader = (props) => {
    return (
        <section id="cover-header" style={{backgroundImage: `url(${props.imguri}), linear-gradient(90deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.805556) 48.81%, rgba(0, 0, 0, 0) 100%)`}}>
            <SEO
                title={props.title}
                description={props.description}
            />
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <h1>{props.title}</h1>
                        <p>{props.description}</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

CoverHeader.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    imguri: PropTypes.string.isRequired
};

export default CoverHeader;
